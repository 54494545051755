import React, { useContext, useState } from "react";
// import "../../../../../static/scss/subdrawer.scss";
// import "./subdrawer.module.scss"
import "./productsDrawer.scss"
import close from "../../../../../static/images/icons/close.svg";
import LoadingAnimation from "../../../../../globalComponents/LoadingAnimation";
import PurpleClose from "../../../../../static/images/purpleClose.svg"
import lock from "../../../../../static/images/icons/lock.svg";
import Axios from "axios";
import * as jose from "jose";
import loadingGif from "../../../../../static/images/loading.gif";
import cloudUploadIcon from "../../../../../static/images/clipIcons/cloudUpload.svg";
import uploadGroupIcon from "../../../../../static/images/uploadGroupIcon.svg"
import { ToastContainer, toast } from "react-toastify";
import { GlobalContex } from "../../../../../globalContex";
import { useEffect } from "react";
import rightArrowColored from "../../../../../static/images/sidebarIcons/rightArrowColored.svg"
import rightArrowPlain from "../../../../../static/images/sidebarIcons/rightArrowPlain.svg"


function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.


const ProductsDataSubDrawer = ({ selectedProduct, setSelectedProduct }) => {
    const [step, setStep] = useState("default")
    const [name, setName] = useState("");
    const [username, setUsername] = useState("")
    const [bio, setBio] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [twitter, setTwitter] = useState("");
    const [country, setCountry] = useState("");
    const [colouredIcon, setColouredIcon] = useState("");
    const [colouredIconLoading, setColouredIconLoading] = useState("");
    const [path, setPath] = useState([]);
    const [successMsg, setSuccessMsg] = useState("")
    const [loadingtext, setloadingtext] = useState("")
    const [currentSubCategory, setCurrentSubCategory] = useState("")
    const [allSubCategories, setAllSubCategories] = useState([])
    const [selectedSubCategory, setSelectedSubCategory] = useState("")
    const [allFilters, setAllFilters] = useState([])
    const [selectedFilters, setSelectedFilters] = useState([])
    const [allBrands, setAllBrands] = useState([])
    const [selectedBrand, setSelectedBrand] = useState("")
    const [allCategories, setAllCategories] = useState([])
    const [currentCategory, setCurrentCategory] = useState("")
    const [selectedCategory, setSelectedCategory] = useState("")
    const [unit, setUnit] = useState("")
    const [weight, setWeight] = useState("")
    const [dimension, setDimension] = useState("")
    const [price, setPrice] = useState("")
    const [unitPrice, setUnitPrice] = useState("")
    const [productTax, setProductTax] = useState(false)
    const [hsnNumber, setHSNnumber] = useState("")
    const [prodDisc1, setProdDisc1] = useState("")
    const [prodDisc2, setProdDisc2] = useState("")
    const [prodDisc3, setProdDisc3] = useState("")
    const [nutritionName, setNutritionName] = useState("")
    const [nutritionUnit, setNutritionUnit] = useState("")
    const [nutritionQuantity, setNutritionQuantity] = useState("")
    const [updatedData, setUpdatedData] = useState("");
    const [imgLoading, setImgLoading] = useState(false);
    const [ingredientName, setIngredientName] = useState("")
    const [ingredientDesc, setIngredientDesc] = useState("")
    const [ingredientLink, setIngredientLink] = useState("")
    const [ingredientQuantity, setIngredientQuantity] = useState("")
    const [howToStoreCheck, setHowToStoreCheck] = useState(false)
    const [howToConsumeCheck, setHowToConsumeCheck] = useState(selectedProduct?.howToConsumeToDisplay)
    const [manageRecipes, setManageRecipes] = useState(selectedProduct?.recipesToDisplay)
    const [manageIngredients, setManageIngredients] = useState(selectedProduct?.ingredientsToDisplay)
    const [manageNutritions, setManageNutritions] = useState(selectedProduct?.nutritionalsToDisplay)
    const [productNum, setProductNum] = useState("")


    const { bankerEmail, setTabSelected, productSubDrawer, setProductSubDrawer, login, loginData,
        refetchProduct, setRefechProduct, productDrawerOpen, setProductsDrawerOpen, selectedBusiness, statusValues } = useContext(GlobalContex);

    useEffect(() => {
        setStep("Action");
        setPath(["Action"]);
    }, [productDrawerOpen]);


    console.log(selectedProduct?.howToStoreToDisplay, "selectedProduct111")

    // useEffect(() => {
    //     fetchCurrentSubCategory()
    // }, [currentSubCategory])

    // useEffect(() => {
    //   console.log(ActionBtn, "ActionBtn")
    //   if (ActionBtn === "Actions") {
    //     setStep("Actions")
    //   } else{
    //     setStep("default")
    //   }
    // }, [step, ActionBtn])

    useEffect(() => {
        setStep(path[path.length - 1]);
    }, [path]);


    useEffect(() => {
        fetchSelectedProduct()
    }, [howToStoreCheck, howToConsumeCheck, manageRecipes, manageIngredients, manageNutritions])

    const fetchSelectedProduct = async () => {
        try {
            let response = await fetch(`https://frissly.apimachine.com/api/product/${selectedProduct?._id}`);
            const jsonData = await response.json();
            const newData = jsonData.data;
            if (jsonData.success) {
                console.log(newData, "newSelectedData")
                setHowToStoreCheck(newData?.howToStoreToDisplay)
                setHowToConsumeCheck(newData?.howToConsumeToDisplay)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const signJwt = async (fileName, emailDev, secret) => {
        try {
            const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
                .setProtectedHeader({ alg: "HS512" })
                .setIssuer("gxjwtenchs512")
                .setExpirationTime("10m")
                .sign(new TextEncoder().encode(secret));
            return jwts;
        } catch (error) {
            console.log(error, "kjbedkjwebdw");
        }
    };

    const handleBreadcrumbClick = (clickedStep) => {
        const foundStepIndex = path.findIndex((o) => o === clickedStep);
        const tempPath = path;
        tempPath.splice(foundStepIndex + 1, tempPath.length);
        setPath([...tempPath]);
        console.log(path, "lwndwed");
    };

    const fetchCurrentSubCategory = async () => {
        let fetchData = await Axios.get(`https://frissly.apimachine.com/api/subcategory/${selectedProduct?.organization?.subCategoryId}`)
        fetchData = fetchData.data
        setCurrentSubCategory(fetchData?.data)

    }
    const fetchAllSubCategory = async () => {
        let fetchData = await Axios.get(`https://frissly.apimachine.com/api/subcategory/?businessusername=${selectedBusiness?.businessusername}`)
        fetchData = fetchData.data
        setAllSubCategories(fetchData?.data)
    }

    const fetchAllCategory = async () => {
        let fetchData = await Axios.get(`https://frissly.apimachine.com/api/category?businessusername=${selectedBusiness?.businessusername}`)
        fetchData = fetchData.data
        setAllCategories(fetchData?.data)
    }

    const fetchCurrentCategory = async () => {
        let fetchData = await Axios.get(`https://frissly.apimachine.com/api/category/${selectedProduct?.organization?.categoryId}`)
        fetchData = fetchData.data
        setCurrentCategory(fetchData?.data)
    }

    const fetchAllFilters = async () => {
        let fetchData = await Axios.get(`https://frissly.apimachine.com/api/filter/?businessusername=${selectedBusiness?.businessusername}`)
        fetchData = fetchData.data
        setAllFilters(fetchData?.data)
    }

    const fetchAllBrands = async () => {
        let fetchData = await Axios.get(`https://frissly.apimachine.com/api/brand/?businessusername=${selectedBusiness?.businessusername}`)
        fetchData = fetchData.data
        setAllBrands(fetchData?.data)
    }

    const handleToggle = (value) => {
        console.log(value, "value")
        let body
        if (value === "howToStoreCheck") {
            body = {
                howToStoreToDisplay: !howToStoreCheck
            }
        }
        if (value === "howToConsumeCheck") {
            body = {
                howToConsumeToDisplay: !howToConsumeCheck
            }
        }
        if (value === "manageRecipes") {
            body = {
                recipesToDisplay: !manageRecipes
            }
        }
        if (value === "manageIngredients") {
            body = {
                ingredientsToDisplay: !manageIngredients
            }
        }
        if (value === "manageNutritions") {
            body = {
                nutritionalsToDisplay: !manageNutritions
            }
        }
        // setStep("loading")
        setloadingtext(`Updating Values`)
        Axios.put(`https://frissly.apimachine.com/api/product/${selectedProduct?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    // setStep("success")
                    // setSuccessMsg(`You have successfully updated status for ${selectedProduct?.name}. You will now be redirected to your updated list.`)
                    setTabSelected("Products")
                    toast.success(data.message || "Updated values");
                    // setProductsDrawerOpen(false);
                    setStep("default");
                    setColouredIcon("")
                    // setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const ActionStatus = (item) => {
        let body = {
            status: item.toLowerCase()
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating Status`)
        Axios.put(`https://frissly.apimachine.com/api/product/${selectedProduct?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully updated status for ${selectedProduct?.name}. You will now be redirected to your updated list.`)
                    setTabSelected("Products")
                    setProductsDrawerOpen(false);
                    setStep("default");
                    setColouredIcon("")
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const clickedFilters = (item) => {
        if (selectedFilters.includes(item?._id)) {
            setSelectedFilters(selectedFilters.filter((filter) => filter !== item?._id));
        } else {
            setSelectedFilters([...selectedFilters, item?._id])
        }
    }

    const updateProductNum = (item) => {
        let body = {
            productOrderCategory: productNum
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating Product Order`)
        Axios.put(`https://frissly.apimachine.com/api/product/${selectedProduct?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully Updated Product Order for ${selectedProduct?.name}. You will now be redirected to your updated list.`)
                    setTimeout(() => {
                        setTabSelected("Products")
                        setStep("default");
                        setProductsDrawerOpen(false);
                        setProductNum("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    // const clickedBrands = (item) => {
    //     if (selectedBrands.includes(item?._id)) {
    //         setSelectedBrands(selectedBrands.filter((filter) => filter !== item?._id));
    //     } else {
    //         setSelectedBrands([...selectedBrands, item?._id])
    //     }
    // }

    // const uploadImage = async (e, setImage, setLoading) => {
    //   setLoading(true);
    //   const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
    //     e.target.files[0].name.lastIndexOf(".")
    //   )}`;
    //   const formData = new FormData();
    //   const file = renameFile(e.target.files[0], fileName);
    //   formData.append("files", file);
    //   const path_inside_brain = "root/";

    //   const jwts = await signJwt(fileName, emailDev, secret);
    //   console.log(jwts, "lkjkswedcf");
    //   let { data } = await Axios.post(
    //     `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
    //     formData,
    //     {
    //       headers: {
    //         "Access-Control-Allow-Origin": "*",
    //       },
    //     }
    //   );
    //   setImage(data.payload.url);
    //   setLoading(false);
    // };

    const uploadImage = async (e, setImage, setLoading) => {
        setLoading(true);
        const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
            e.target.files[0].name.lastIndexOf('.')
        )}`;
        const formData = new FormData();
        const file = renameFile(e.target.files[0], fileName);
        formData.append('files', file);
        const path_inside_brain = 'root/';

        const jwts = await signJwt(fileName, emailDev, secret);
        // console.log(jwts, 'lkjkswedcf');
        let { data } = await Axios.post(
            `https://insurance.apimachine.com/insurance/general/upload`,
            formData,
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }
        );
        console.log(data[0].urlName, 'drive uploaded img');
        setImage(data[0].urlName);
        setLoading(false);
    };


    // const validate = () => {
    //   if (
    //     name &&
    //     username &&
    //     bio &&
    //     linkedIn &&
    //     twitter &&
    //     country &&
    //     colouredIcon
    //   ) {
    //     let data = {
    //       name,
    //       user_name: username,
    //       email: bankerEmail,
    //       description: bio,
    //       social_media: [{ "LinkedIn": linkedIn, "Twitter": twitter }],
    //       profile_pic: colouredIcon,
    //       country,
    //     }
    //     addProfile(data)
    //   } else {
    //     toast.warning("Please fill all the fields")
    //   }
    // }

    const ImageUploadAPI = () => {
        let body = {
            email: bankerEmail,
            token: login?.idToken,
            field_name: "product_icon",
            field_value: colouredIcon,
            product_id: selectedProduct.product_id
        }
        console.log(body, "body")
        setStep("loading")
        setloadingtext(`Updating Icon...`)
        Axios.post("https://comms.globalxchange.io/gxb/product/edit", body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg("You have successfully edited product icon. You will be redirected to the updated Products page now.")
                    setTabSelected("Products")
                    setStep("default");
                    setProductSubDrawer(false);
                    setColouredIcon("")
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
        // .finally(() => {
        //   setTabSelected("My Profile")
        //   setStep("default");
        //   SetShowDrawer(false);
        //   setRefechProfile(!refechProfile)
        // })
    }

    const deleteProduct = () => {
        let body = {
            status: "inactive"
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Deleting ${selectedProduct?.name}...`)
        Axios.put(`https://frissly.apimachine.com/api/product/${selectedProduct?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully deleted ${selectedProduct?.name}. You will now be redirected to your updated product list.`)
                    setTimeout(() => {
                        setTabSelected("Products")
                        setStep("default");
                        setProductsDrawerOpen(false);
                        setColouredIcon("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const updateSubCategory = () => {
        let body = {
            subCategoryId: selectedSubCategory?._id
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating Sub Category For ${selectedProduct?.name}...`)
        Axios.put(`https://frissly.apimachine.com/api/product/organization/${selectedProduct?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully updated category for ${selectedProduct?.name}. You will now be redirected to your updated product list.`)
                    setTimeout(() => {
                        setTabSelected("Products")
                        setStep("default");
                        setProductsDrawerOpen(false);
                        setColouredIcon("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const updateCategory = () => {
        let body = {
            categoryId: selectedCategory?._id
        }
        setStep("loading")
        setloadingtext(`Updating Category For ${selectedProduct?.name}...`)
        Axios.put(`https://frissly.apimachine.com/api/product/organization/${selectedProduct?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully updated category for ${selectedProduct?.name}. You will now be redirected to your updated product list.`)
                    setTimeout(() => {
                        setTabSelected("Products")
                        setStep("default");
                        setProductsDrawerOpen(false);
                        setColouredIcon("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const ActionPrice = () => {
        let body = {
            weight,
            dimensions: dimension,
            price,
            unit,
            taxOnProduct: productTax,
            costPerItem: unitPrice,
            hsnNumber
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Adding New Price For ${selectedProduct?.name}...`)
        Axios.put(`https://frissly.apimachine.com/api/product/add/dimension/price/${selectedProduct?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully updated prices for ${selectedProduct?.name}. You will now be redirected to your updated product list.`)
                    setTimeout(() => {
                        setTabSelected("Products")
                        setStep("default");
                        setProductsDrawerOpen(false);
                        setUnit("")
                        setPrice("")
                        setWeight("")
                        setDimension("")
                        setProductTax(false)
                        setUnitPrice("")
                        setHSNnumber("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const NutritionAction = () => {
        let body = {
            name: nutritionName,
            unit: nutritionUnit,
            quantity: nutritionQuantity
        }
        setStep("loading")
        setloadingtext(`Adding Nutritional Facts For ${selectedProduct?.name}...`)
        Axios.patch(`https://frissly.apimachine.com/api/product/add/nutritional/${selectedProduct?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully added Nutritional Facts for ${selectedProduct?.name}. You will now be redirected to your updated product list.`)
                    setTimeout(() => {
                        setTabSelected("Products")
                        setStep("default");
                        setProductsDrawerOpen(false);
                        setUnit("")
                        setPrice("")
                        setWeight("")
                        setDimension("")
                        setProductTax(false)
                        setUnitPrice("")
                        setHSNnumber("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const NutritionBack = () => {
        let newData = path.filter(item => item !== "Add nutritional fact")
        setPath(newData)
        setStep("Nutritional Facts")
    }

    const IngredientAction = () => {
        if (isNaN(ingredientQuantity)) return toast.info("Quantity of ingredient should be a number")
        let body = {
            name: ingredientName,
            icon: updatedData,
            description: ingredientDesc,
            link: ingredientLink,
            productMakeup: ingredientQuantity
        }
        setStep("loading")
        setloadingtext(`Adding New Ingredient ${selectedProduct?.name}...`)
        Axios.patch(`https://frissly.apimachine.com/api/product/add/ingredient/${selectedProduct?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        // toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully added New Ingredient ${selectedProduct?.name}. You will now be redirected to your updated product list.`)
                    setTimeout(() => {
                        setTabSelected("Products")
                        setStep("default");
                        setProductsDrawerOpen(false);
                        setUnit("")
                        setPrice("")
                        setWeight("")
                        setDimension("")
                        setProductTax(false)
                        setUnitPrice("")
                        setHSNnumber("")
                        setIngredientName("")
                        setIngredientDesc("")
                        setIngredientLink("")
                        setIngredientQuantity("")
                        setUpdatedData("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }


    const updateFilters = () => {
        if (selectedFilters.length === 0) return toast.info("Please select atleast one filter")
        let body = {
            filters: selectedFilters
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating Filters For ${selectedProduct?.name}...`)
        Axios.put(`https://frissly.apimachine.com/api/product/organization/${selectedProduct?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully updated filters for ${selectedProduct?.name}. You will now be redirected to your updated product list.`)
                    setTimeout(() => {
                        setTabSelected("Products")
                        setStep("default");
                        setProductsDrawerOpen(false);
                        setColouredIcon("")
                        setSelectedFilters([])
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const updateBrands = () => {
        if (!selectedBrand) return toast.info("Please select atleast one brand")
        let body = {
            brands: selectedBrand
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating Brands For ${selectedProduct?.name}...`)
        Axios.put(`https://frissly.apimachine.com/api/product/organization/${selectedProduct?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully updated brands for ${selectedProduct?.name}. You will now be redirected to your updated product list.`)
                    setTimeout(() => {
                        setTabSelected("Products")
                        setStep("default");
                        setProductsDrawerOpen(false);
                        setColouredIcon("")
                        setSelectedBrand("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const ActionDiscount = () => {
        let body = {
            relatedProductId: selectedProduct?._id
        }
        if (prodDisc1) body.comboDiscount2 = prodDisc1
        if (prodDisc2) body.comboDiscount3 = prodDisc2
        if (prodDisc3) body.comboDiscount4plus = prodDisc3
        setStep("loading")
        setloadingtext(`Updating Discounts For ${selectedProduct?.name}...`)
        Axios.put(`https://frissly.apimachine.com/api/product/related/${selectedProduct?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully updated discount for ${selectedProduct?.name}. You will now be redirected to your updated product list.`)
                    setTimeout(() => {
                        setTabSelected("Products")
                        setStep("default");
                        setProductsDrawerOpen(false);
                        setProdDisc1("")
                        setProdDisc2("")
                        setProdDisc3("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }




    const fullHeightDrawer = (message) => {
        if (message) {
            return (
                <div
                    style={{
                        height: window.innerHeight - 150,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                            marginBottom: "20px",
                        }}
                    >
                        {message}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        height: window.innerHeight - 123,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                        }}
                    >
                        Updating Icon...
                    </div>
                </div>
            );
        }
    };


    const conditionalSteps = () => {
        switch (step) {
            case "loading":
                return fullHeightDrawer(loadingtext);
            case "Token Expired":
                return (
                    <>
                        <div className="sidebarTitle">Token Expired. Login Again.</div>
                        <div
                            className="sidebarCard"
                            onClick={(e) => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            <img src={lock} alt="" style={{ width: "20px" }} />
                            <div style={{ paddingLeft: "10px" }}>Logout</div>
                        </div>
                    </>
                );
            case "success":
                return (
                    <div
                        className="assetDispText"
                        style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "60vh",
                            padding: "0px 20px",
                        }}
                    >
                        {successMsg}
                    </div>
                );
            case "Update Order":
                return (
                    <>
                        <div className="sidebarTitle">Current order</div>
                        <div
                            className="inputStyle"
                        // onClick={deleteCategory}
                        >
                            <div>{selectedProduct?.productOrderCategory ? selectedProduct?.productOrderCategory : "--"}</div>
                        </div>
                        <div className="sidebarTitle">New Order</div>
                        <div
                        >
                            <input type="number" placeholder="Enter order..." className="inputStyle" value={productNum} onChange={(e) => setProductNum(e.target.value)} />
                        </div>
                        <div className="bottomSubmit" style={{ background: "#E92729" }} onClick={() => updateProductNum()}>
                            <p className="btm-text">Submit</p>
                        </div>
                        <div className="bottomSubmit" style={{ bottom: "10px", background: "white", border: "1px solid #E7E7E7" }} onClick={() => {
                            setStep("Action");
                            setPath(["Action"]);
                        }}>
                            <p className="btm-text" style={{ color: "black" }}>Go Back</p>
                        </div>
                    </>
                )
            case "Edit Product":
                return (
                    <>
                        <div className="sidebarTitle">What element do you want to edit?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Icon");
                                    setPath([...path, "Icon"]);
                                }}
                            >
                                {/* <img
              src={editPub}
              alt=""
              className="coin"
              style={{ width: "25px", height: "25px" }}
            /> */}
                                <div>Icon</div>
                            </div>
                        </div>
                    </>
                )
            case "Icon":
                return (
                    <>
                        <div className="productScrollDrawer">
                            {/* <div className="storyname" style={{ paddingTop: "20px" }}>
            Name
          </div> */}
                            <div>
                                <div className="storyname">Current Icon</div>

                                <div
                                    className="filesUpload ImgBox"
                                    style={{ justifyContent: "flex-start", width: "100%", height: "250px" }}
                                >
                                    <div className="fileInp icon flexProduct">
                                        <img
                                            className={`${Boolean(colouredIcon)}`}
                                            src={
                                                selectedProduct?.product_icon

                                            }
                                            alt=""
                                            style={{ width: "95%", height: "95%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="storyname">New Icon</div>

                                <div
                                    className="filesUpload ImgBox"
                                    style={{ justifyContent: "flex-start", width: "100%" }}
                                >
                                    <label className="fileInp icon flexProduct">
                                        <img
                                            className={`${Boolean(colouredIcon)}`}
                                            src={
                                                colouredIconLoading
                                                    ? loadingGif
                                                    : colouredIcon || uploadGroupIcon
                                            }
                                            alt=""
                                            style={{ width: "100px" }}
                                        />
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                uploadImage(
                                                    e,
                                                    setColouredIcon,
                                                    setColouredIconLoading
                                                );
                                            }}
                                            accept="image/*"
                                        />

                                        <div className="hovTxt">
                                            Upload
                                            <br />
                                            New
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="bottomProductBtn">
                                <button className="updateProductBtn" onClick={ImageUploadAPI}>Update Product</button>
                            </div>
                        </div>
                    </>
                );
            case "Change Status":
                return (
                    <>
                        <div className="sidebarTitle">Select new status</div>
                        <div className="sidebarScroll">
                            {statusValues.map((item, value) => {
                                return (
                                    <div className="sidebarCard" style={{ background: item?.toLowerCase() === selectedProduct?.status.toLowerCase() ? "#E92729" : "" }} onClick={() => ActionStatus(item)}>
                                        <div style={{ color: item?.toLowerCase() === selectedProduct?.status.toLowerCase() ? "white" : "" }}>{item}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )
            case "Update Filters":
                return (
                    <div className="sidebarScroll">
                        <div className="sidebarTitle">Selected updated list of filters</div>
                        {allFilters.map((e, index) => {
                            return <div id={index}
                                className="sidebarCard"
                                style={{ background: selectedFilters.includes(e?._id) ? "#E92729" : "", color: selectedFilters.includes(e?._id) ? "white" : "black" }}
                                onClick={() => {
                                    clickedFilters(e)
                                }}
                            >
                                <div>{e.name}</div>
                            </div>
                        })}
                        <div className="sidebarCard bottomCard1" onClick={() => updateFilters()}>Next</div>
                    </div>
                )
            case "Update Brand":
                return (
                    <div className="sidebarScroll">
                        <div className="sidebarTitle">Selected updated list of brands</div>
                        {allBrands.map((e, index) => {
                            return <div onClick={() => setSelectedBrand(e?._id)}>
                                <div id={index}
                                    className="sidebarCard"
                                    style={{ background: selectedBrand === e?._id ? "#E92729" : "", color: selectedBrand === e?._id ? "white" : "black" }}
                                >
                                    <div>{e.name}</div>
                                </div>
                            </div>
                        })}
                        <div className="sidebarCard bottomCard1" onClick={updateBrands}>Next</div>
                    </div>
                )
            case "Delete Product":
                return (
                    <>
                        <div className="sidebarTitle">Are you sure you want to delete this product?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={deleteProduct}
                            >
                                <div>Yes delete it</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Action");
                                    setPath(["Action"]);
                                }}
                            >
                                <div>Never mind</div>
                            </div>
                        </div>
                    </>
                )
            case "Update Sub-Category":
                return (
                    <>
                        <div className="sidebarTitle">Current Sub-Category</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                            >
                                <div>{currentSubCategory ? currentSubCategory?.name : "No Sub Category present"}</div>
                            </div>
                            <br />
                            <div className="sidebarTitle">Select new Sub-Category</div>
                            {allSubCategories.filter(item => item?.name !== currentSubCategory?.name).map((e, index) => {
                                return <div id={index}
                                    className="sidebarCard"
                                    onClick={() => {
                                        setStep("Confirm Sub-Category");
                                        setPath([...path, "Confirm Sub-Category"]);
                                        setSelectedSubCategory(e)
                                    }}
                                >
                                    <div>{e.name}</div>
                                </div>
                            })}
                        </div>
                    </>
                )

            case "Update Category":
                return (
                    <>
                        <div className="sidebarTitle">Current Category</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                            >
                                <div>{currentCategory ? currentCategory?.name : "No Category present"}</div>
                            </div>
                            <br />
                            <div className="sidebarTitle">Select new Category</div>
                            {allCategories.filter(item => item?.name !== currentCategory?.name).map((e, index) => {
                                return <div id={index}
                                    className="sidebarCard"
                                    onClick={() => {
                                        setStep("Confirm Category");
                                        setPath([...path, "Confirm Category"]);
                                        setSelectedCategory(e)
                                    }}
                                >
                                    <div>{e.name}</div>
                                </div>
                            })}
                        </div>
                    </>
                )

            case "Confirm Sub-Category":
                return (
                    <>
                        <div className="sidebarTitle">Are you sure you want to update the sub-category for this product?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={updateSubCategory}
                            >
                                <div>Yes update it</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Sub-Category");
                                    setPath(["Action", "Update Sub-Category"]);
                                }}
                            >
                                <div>Never mind</div>
                            </div>
                        </div>
                    </>
                )
            case "Manage Bulk Discounts":
                return (
                    <div className="sidebarScroll">
                        <div className="sidebarTitle">Current discount when someone buys 2 units</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={selectedProduct?.relatedProduct ? selectedProduct?.relatedProduct?.discounts?.[0]?.percentage?.$numberDecimal : "Not yet set"} placeholder="Current discount" disabled />
                        </div>
                        <div className="sidebarTitle">New discount when someone buys 2 units</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={prodDisc1} onChange={(e) => setProdDisc1(e.target.value)} placeholder="Enter %" />
                        </div>
                        <hr style={{ margin: "30px 0px" }} />
                        <div className="sidebarTitle">New discount when someone buys 3 units</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={selectedProduct?.relatedProduct ? selectedProduct?.relatedProduct?.discounts?.[1]?.percentage?.$numberDecimal : "Not yet set"} placeholder="Current discount" disabled />
                        </div>
                        <div className="sidebarTitle">New discount when someone buys 3 units</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={prodDisc2} onChange={(e) => setProdDisc2(e.target.value)} placeholder="Enter %" />
                        </div>
                        <hr style={{ margin: "30px 0px" }} />
                        <div className="sidebarTitle">New discount when someone buys 4 units</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={selectedProduct?.relatedProduct ? selectedProduct?.relatedProduct?.discounts?.[2]?.percentage?.$numberDecimal : "Not yet set"} placeholder="Current discount" disabled />
                        </div>
                        <div className="sidebarTitle">New discount when someone buys 4 units</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={prodDisc3} onChange={(e) => setProdDisc3(e.target.value)} placeholder="Enter %" />
                        </div>
                        <div className="theBottomSubmitCard" onClick={() => ActionDiscount()}>Submit</div>
                        <div className="theBottomCard" onClick={() => setPath(["Action"])}>Go Back</div>
                    </div>
                )
            case "Add nutritional fact":
                return (
                    <div className="sidebarScroll">
                        <div className="sidebarTitle">Name of nutritional fact</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={nutritionName} onChange={(e) => setNutritionName(e.target.value)} placeholder="Ex: Calories" />
                        </div>
                        <div className="sidebarTitle">Unit for nutritional fact</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={nutritionUnit} onChange={(e) => setNutritionUnit(e.target.value)} placeholder="Ex: 100" />
                        </div>
                        <div className="sidebarTitle">Quantity of nutritional fact</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={nutritionQuantity} onChange={(e) => setNutritionQuantity(e.target.value)} placeholder="Ex: 100" />
                        </div>
                        <div className="theBottomSubmitCard" onClick={() => NutritionAction()}>Submit</div>
                        <div className="theBottomCard" onClick={() => NutritionBack()}>Go Back</div>
                    </div>
                )
            case "Add new price":
                return (
                    <div className="sidebarScroll">
                        <div className="sidebarTitle">What unit is the weight?</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={unit} onChange={(e) => setUnit(e.target.value)} placeholder="Ex: Grams" />
                        </div>
                        <div className="sidebarTitle">What is the weight?</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={weight} onChange={(e) => setWeight(e.target.value)} placeholder="Ex: 100" />
                        </div>
                        <div className="sidebarTitle">What are the dimensions?</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={dimension} onChange={(e) => setDimension(e.target.value)} placeholder="Ex: 100" />
                        </div>
                        <div className="sidebarTitle">What is the price?</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Ex: 100" />
                        </div>
                        <div className="sidebarTitle">What is the price per unit?</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={unitPrice} onChange={(e) => setUnitPrice(e.target.value)} placeholder="Ex: 100" />
                        </div>
                        <div className="sidebarTitle">Is there tax on the product?</div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="theSidebarButton" style={{ background: productTax ? "rgb(102, 105, 176)" : "" }} onClick={() => setProductTax(true)}>Yes</div>
                            <div className="theSidebarButton" style={{ background: !productTax ? "rgb(102, 105, 176)" : "" }} onClick={() => setProductTax(false)}>No</div>
                        </div>
                        <div className="sidebarTitle">What is the HSN number?</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={hsnNumber} onChange={(e) => setHSNnumber(e.target.value)} placeholder="Ex: 123456" />
                        </div>
                        <div className="theBottomSubmitCard" onClick={() => ActionPrice()}>Submit</div>
                        <div className="theBottomCard">Go Back</div>
                    </div>
                )
            case "Add new ingredient":
                return (
                    <div className="sidebarScroll">
                        <div className="sidebarTitle">Name of ingredient</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={ingredientName} onChange={(e) => setIngredientName(e.target.value)} placeholder="Ex. sugar" />
                        </div>
                        <div className="sidebarTitle">Describe the ingredient</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={ingredientDesc} onChange={(e) => setIngredientDesc(e.target.value)} placeholder="Ex. sugar is useful" />
                        </div>
                        <div className="sidebarTitle">Link to purchase ingredient</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={ingredientLink} onChange={(e) => setIngredientLink(e.target.value)} placeholder="Ex. 0.35" />
                        </div>
                        <div className="sidebarTitle">Quantity of ingredient</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={ingredientQuantity} onChange={(e) => setIngredientQuantity(e.target.value)} placeholder="Ex. 0.35" />
                        </div>
                        <div className="sidebarTitle">Icon</div>
                        <div className="filesUpload" style={{ height: "120px", padding: 0 }}>
                            <label
                                className="fileInp icon"
                                style={{
                                    flex: "0 0 100%",
                                    maxWidth: "103px",
                                    height: "100px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}
                            >
                                <img
                                    style={{ width: updatedData ? "100px" : "46px", height: updatedData ? "100px" : "46px" }}
                                    className={`${Boolean(updatedData)}`}
                                    src={
                                        imgLoading
                                            ? loadingGif
                                            : updatedData || cloudUploadIcon
                                    }
                                    alt=""
                                />
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        uploadImage(e, setUpdatedData, setImgLoading);
                                    }}
                                    accept="image/*"
                                />

                                <div className="hovTxt">
                                    Upload
                                    <br />
                                    New
                                </div>
                            </label>
                        </div>
                        <div className="theBottomCard">Go Back</div>
                        <div className="theBottomSubmitCard" onClick={() => IngredientAction()}>Submit</div>
                    </div>
                )
            case "Add new price/dimension":
                return (
                    <>
                        <div className="sidebarTitle">What would you like to do?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Add new price");
                                    setPath([...path, "Add new price"]);
                                }}
                            >
                                <div>Add new price</div>
                            </div>
                            <div
                                className="sidebarCard"
                            // onClick={() => {
                            //     setStep("Update Sub-Category");
                            //     setPath(["Action", "Update Sub-Category"]);
                            // }}
                            >
                                <div>Edit/remove existing price</div>
                            </div>
                        </div>
                    </>
                )
            case "Nutritional Facts":
                return (
                    <>
                        <div className="sidebarTitle">What would you like to do?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Add nutritional fact");
                                    setPath([...path, "Add nutritional fact"]);
                                }}
                            >
                                <div>Add nutritional fact</div>
                            </div>
                            <div
                                className="sidebarCard"
                            // onClick={() => {
                            //     setStep("Update Sub-Category");
                            //     setPath(["Action", "Update Sub-Category"]);
                            // }}
                            >
                                <div>Edit/remove nutritional fact</div>
                            </div>
                        </div>
                    </>
                )

            case "Manage Price/Dimension":
                return (
                    <>
                        <div className="sidebarTitle">What would you like to do?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Add new price/dimension");
                                    setPath([...path, "Add new price/dimension"]);
                                }}
                            >
                                <div>Add new price/dimension</div>
                            </div>
                            <div
                                className="sidebarCard"
                            // onClick={() => {
                            //     setStep("Update Sub-Category");
                            //     setPath(["Action", "Update Sub-Category"]);
                            // }}
                            >
                                <div>Edit/remove existing price price/dimension</div>
                            </div>
                        </div>
                    </>
                )

            case "Confirm Category":
                return (
                    <>
                        <div className="sidebarTitle">Are you sure you want to update the category for this product?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={updateCategory}
                            >
                                <div>Yes update it</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Sub-Category");
                                    setPath(["Action", "Update Sub-Category"]);
                                }}
                            >
                                <div>Never mind</div>
                            </div>
                        </div>
                    </>
                )
            case "Manage Ingredients":
                return (
                    <>
                        <div className="sidebarTitle">What would you like to do?</div>
                        <div className="sidebarScroll">
                            <div className="sidebarCard"
                                onClick={() => {
                                    setStep("Add new ingredient");
                                    setPath([...path, "Add new ingredient"]);
                                }}
                            >
                                <div>Add new ingredient</div>
                            </div>
                            <div className="sidebarCard">
                                <div>Edit existing ingredient</div>
                            </div>
                            <div className="sidebarCard">
                                <div>Remove existing ingredient</div>
                            </div>
                        </div>
                    </>
                )
            default:
                return (
                    <>
                        <div className="sidebarScroll">
                            <div className="sidebarSubTitle">Hierarchy management</div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Category");
                                    setPath([...path, "Update Category"]);
                                    fetchCurrentCategory()
                                    fetchAllCategory()
                                }}
                            >
                                <div>Update Category</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Sub-Category");
                                    setPath([...path, "Update Sub-Category"]);
                                    fetchCurrentSubCategory()
                                    fetchAllSubCategory()
                                }}
                            >
                                <div>Update Sub-Category</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Brand");
                                    setPath([...path, "Update Brand"]);
                                    fetchAllBrands()
                                }}
                            >
                                <div>Update Brand</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Filters");
                                    setPath([...path, "Update Filters"]);
                                    fetchAllFilters()
                                }}
                            >
                                <div>Update Filters</div>
                            </div>
                            <div
                                className="sidebarCard"
                            // onClick={() => {
                            //     setStep("Update Sub-Category");
                            //     setPath([...path, "Update Sub-Category"]);
                            //     fetchCurrentSubCategory()
                            //     fetchAllSubCategory()
                            // }}
                            >
                                <div>Update Sub 2-Categories</div>
                            </div>
                            <div className="sidebarSubTitle">Pricing management</div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Manage Price/Dimension");
                                    setPath([...path, "Manage Price/Dimension"]);
                                }}
                            >
                                <div>Manage Prices/Dimension</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Manage Bulk Discounts");
                                    setPath([...path, "Manage Bulk Discounts"]);
                                }}
                            >
                                <div>Manage Bulk Discounts</div>
                            </div>
                            <p className="sidebarSubTitle">Product page enhancements</p>
                            <div
                                className="sidebarCard flexToggle"
                            // onClick={() => {
                            //     setStep("Nutritional Facts");
                            //     setPath([...path, "Nutritional Facts"]);
                            // }}
                            >
                                <div>Manage Nutritional Facts</div>
                                <div className="flexToggle">
                                    <div class="switch" onClick={() => {
                                        handleToggle("manageNutritions")
                                        setManageNutritions(!manageNutritions)
                                    }} >
                                        <input type="checkbox" checked={manageNutritions} />
                                        <span class="slider round"></span>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div onClick={manageNutritions ? () => {
                                        setStep("Nutritional Facts");
                                        setPath([...path, "Nutritional Facts"]);
                                    } : null}>
                                        <img src={manageNutritions ? rightArrowColored : rightArrowPlain} alt="rightArrow" />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="sidebarCard flexToggle"
                            // onClick={() => {
                            //     setStep("Manage Ingredients");
                            //     setPath([...path, "Manage Ingredients"]);
                            // }}
                            >
                                <div>Manage Ingredients</div>
                                <div className="flexToggle">
                                    <div class="switch" onClick={() => {
                                        handleToggle("manageIngredients")
                                        setManageIngredients(!manageIngredients)
                                    }}>
                                        <input type="checkbox" checked={manageIngredients} />
                                        <span class="slider round"></span>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div onClick={manageIngredients ? () => {
                                        setStep("Manage Ingredients");
                                        setPath([...path, "Manage Ingredients"]);
                                    } : null}>
                                        <img src={manageIngredients ? rightArrowColored : rightArrowPlain} alt="rightArrow" />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="sidebarCard flexToggle"
                            // onClick={() => {
                            //     setStep("Manage Ingredients");
                            //     setPath([...path, "Manage Ingredients"]);
                            // }}
                            >
                                <div>Manage Recipes</div>
                                <div className="flexToggle">
                                    <div class="switch" onClick={() => {
                                        handleToggle("manageRecipes")
                                        setManageRecipes(!manageRecipes)
                                    }} >
                                        <input type="checkbox" checked={manageRecipes} />
                                        <span class="slider round"></span>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div
                                    // onClick={manageIngredients ? () => {
                                    //     setStep("Manage Ingredients");
                                    //     setPath([...path, "Manage Ingredients"]);
                                    // } : null}
                                    >
                                        <img src={manageRecipes ? rightArrowColored : rightArrowPlain} alt="rightArrow" />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="sidebarCard flexToggle"
                            // onClick={() => {
                            //     setStep("Manage Ingredients");
                            //     setPath([...path, "Manage Ingredients"]);
                            // }}
                            >
                                <div>Manage “how to store” instructions</div>
                                <div className="flexToggle">
                                    <div class="switch" onClick={() => {
                                        handleToggle("howToStoreCheck")
                                        setHowToStoreCheck(!howToStoreCheck)
                                    }} >
                                        <input type="checkbox" checked={howToStoreCheck} />
                                        <span class="slider round"></span>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div
                                    // onClick={manageIngredients ? () => {
                                    //     setStep("Manage Ingredients");
                                    //     setPath([...path, "Manage Ingredients"]);
                                    // } : null}
                                    >
                                        <img src={howToStoreCheck ? rightArrowColored : rightArrowPlain} alt="rightArrow" />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="sidebarCard flexToggle"
                            // onClick={() => {
                            //     setStep("Manage Ingredients");
                            //     setPath([...path, "Manage Ingredients"]);
                            // }}
                            >
                                <div>Manage “how to consume" instructions</div>
                                <div className="flexToggle">
                                    <div class="switch" onClick={() => {
                                        handleToggle("howToConsumeCheck")
                                        setHowToConsumeCheck(!howToConsumeCheck)
                                    }} >
                                        <input type="checkbox" checked={howToConsumeCheck} />
                                        <span class="slider round"></span>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div
                                    // onClick={manageIngredients ? () => {
                                    //     setStep("Manage Ingredients");
                                    //     setPath([...path, "Manage Ingredients"]);
                                    // } : null}
                                    >
                                        <img src={howToConsumeCheck ? rightArrowColored : rightArrowPlain} alt="rightArrow" />
                                    </div>
                                </div>
                            </div>
                            <p className="sidebarSubTitle">Database management</p>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Change Status");
                                    setPath([...path, "Change Status"]);
                                }}
                            >
                                <div>Change Status</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Order");
                                    setPath([...path, "Update Order"]);
                                }}
                            >
                                <div>Update Order</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Delete Product");
                                    setPath([...path, "Delete Product"]);
                                }}
                            >
                                <div>Delete Product</div>
                            </div>
                        </div>
                    </>
                )
        }
    }



    return (
        <div
            className={productDrawerOpen ? "right-drawer-visible" : "right-drawer-hidden"}
            style={{ height: window.innerHeight - 123, top: 132 }}
        >
            <div
                style={{
                    padding: "30px",
                    height: "90px",
                }}
            >
                {
                    step === "loading" ||
                        step === "Token Expired" ||
                        step == "success" ? (
                        ""
                    ) : (
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* <img
                  src={
                    selectedBusiness?.profile_pic
                      ? selectedBusiness?.profile_pic
                      : defaultImg
                  }
                  alt=""
                  style={{
                    width: "28px",
                    height: "28px",
                    borderRadius: "100%",
                  }}
                /> */}
                                    <div>
                                        <div style={{ fontSize: "22px" }}>
                                            {selectedProduct?.name}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* {path.length > 1 ? (
                  <div
                    className="backButton icon-imgs"
                    onClick={(e) => handleBackStep()}
                  >
                    <img src={back} alt="" />
                  </div>
                ) : (
                  ""
                )} */}
                                    <div
                                        className="backButton icon-imgs colorImg"
                                        onClick={(e) => {
                                            setPath([]);
                                            setProductsDrawerOpen(false);
                                        }}
                                        style={{
                                            marginLeft: "20px",
                                        }}
                                    >
                                        <img src={PurpleClose} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div
                                className="breadcrumb"
                                style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
                            >
                                {path?.map((item, index) => {
                                    return (
                                        <div style={{ marginTop: "5px" }}>
                                            {index !== 0 ? <span>&nbsp;{`->`}&nbsp;</span> : ""}
                                            <span
                                                onClick={(e) => handleBreadcrumbClick(item)}
                                                className={
                                                    index === path.length - 1 ? "crumbSelected" : "crumbs"
                                                }
                                            >
                                                {item}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                {conditionalSteps()}
                <ToastContainer />
            </div>
        </div>
    );
};

export default ProductsDataSubDrawer;
