import React, { useState, useContext, useEffect } from "react";
import { GlobalContex } from "../../../../globalContex";
import "./overview.css";
import arrowLeft from "../../../../static/images/icons/arrowLeft.svg";
import arrowRight from "../../../../static/images/icons/arrowRight.svg";
import axios from "axios";

const Overview = () => {
  const {
    setAllCoins,

    selectedCoin,
    setSelectedCoin,
    selectedFilter2,
    setSelectedFilter2,
    refetchApi,
    setRefetchApi,
    bankerEmail,
    selectedFilter1,
    FormatNumber,
  } = useContext(GlobalContex);

  const [step, setStep] = useState(0);
  const [dataLoading, setDataLoading] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const [selectedCard, setSelectedCard] = useState(null);
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    console.log(selectedFilter1, "wkjbekwebfw");
    if (selectedFilter1?.name === "User") {
      setDataLoading(true);
      axios
        .get(
          selectedFilter2
            ? `https://comms.globalxchange.io/coin/iced/banker/custom/bond/cummulative/bond/spread/stats?bond_creator=${bankerEmail}&displayCurrency=${
                selectedCoin ? selectedCoin.coinSymbol : "USD"
              }&customer_email=${selectedFilter2}`
            : `https://comms.globalxchange.io/coin/iced/banker/custom/bond/cummulative/bond/spread/stats?bond_creator=${bankerEmail}&displayCurrency=${
                selectedCoin ? selectedCoin.coinSymbol : "USD"
              }`
        )
        .then((res) => {
          setCardData(res.data.total_stats);
          setDataLoading(false);
          console.log(res.data, "kwhjvcjhwevjwehvd");
          setSelectedCard({
            line1: "capital_invested",
            line2: "capital_raised",
            line3: "capital_difference",
            amount: res.data.total_stats?.capital_raised,
            name: "Capital Raised",
          });
          setRefetchApi(false);
        });
    } else if (selectedFilter1?.name === "Affiliate") {
      setDataLoading(true);
      axios
        .get(
          selectedFilter2
            ? `https://comms.globalxchange.io/coin/iced/banker/custom/bond/cummulative/bond/spread/stats?bond_creator=${bankerEmail}&displayCurrency=${
                selectedCoin ? selectedCoin.coinSymbol : "USD"
              }&affiliate_email=${selectedFilter2}`
            : `https://comms.globalxchange.io/coin/iced/banker/custom/bond/cummulative/bond/spread/stats?bond_creator=${bankerEmail}&displayCurrency=${
                selectedCoin ? selectedCoin.coinSymbol : "USD"
              }`
        )
        .then((res) => {
          setCardData(res.data.total_stats);
          setDataLoading(false);
          console.log(res.data, "kwhjvcjhwevjwehvd");
          setSelectedCard({
            line1: "capital_invested",
            line2: "capital_raised",
            line3: "capital_difference",
            amount: res.data.total_stats?.capital_raised,
            name: "Capital Raised",
          });
          setRefetchApi(false);
        });
    }
  }, [bankerEmail, selectedCoin, refetchApi]);

  const stepUp = () => {
    if (step < 2) {
      setStep(step + 1);
    }
  };
  const stepDown = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const conditionalCards = () => {
    if (step === 0) {
      return (
        <div className="cardGroup1">
          <div className="grid">
            {!dataLoading ? (
              <div className="cell">
                <div
                  onClick={(e) =>
                    setSelectedCard({
                      line1: "capital_invested",
                      line2: "capital_raised",
                      line3: "capital_difference",
                      amount: cardData?.capital_raised,
                      name: "Capital Raised",
                    })
                  }
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}{" "}
                      {FormatNumber(cardData?.capital_invested, 3)}
                    </div>
                    <div className="cell-subheader">Capital Invested</div>
                  </div>
                  <div>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.capital_raised, 3)}
                    </div>
                    <div className="cell-subheader">Capital Raised</div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 0.5px lightgray",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.capital_difference, 3)}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cell skeleton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="skeleton-left" style={{ paddingTop: "30px" }}>
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                </div>
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                </div>
                <div
                  style={{
                    borderTop: "solid 0.5px lightgray",
                    paddingTop: "30px",
                  }}
                >
                  <div className="skeleton-left">
                    <div
                      className="line"
                      style={{ width: "40%", height: "20px" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {!dataLoading ? (
              <div className="cell">
                <div
                  onClick={(e) =>
                    setSelectedCard({
                      line1: "capital_invested_without_renewed",
                      line2: "capital_raised_without_renewed",
                      line3: "capital_without_renewed_difference",
                      amount: cardData?.capital_raised,
                      name: "Capital Raised Minus Renewals",
                    })
                  }
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(
                        cardData?.capital_invested_without_renewed,
                        3
                      )}
                    </div>
                    <div className="cell-subheader">
                      Capital Invested Minus Renewals
                    </div>
                  </div>
                  <div>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(
                        cardData?.capital_raised_without_renewed,
                        3
                      )}
                    </div>
                    <div className="cell-subheader">
                      Capital Raised Minus Renewals
                    </div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 0.5px lightgray",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(
                        cardData?.capital_without_renewed_difference,
                        3
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cell skeleton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="skeleton-left" style={{ paddingTop: "30px" }}>
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "60%", height: "10px" }}
                  ></div>
                </div>
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "60%", height: "10px" }}
                  ></div>
                </div>
                <div
                  style={{
                    borderTop: "solid 0.5px lightgray",
                    paddingTop: "30px",
                  }}
                >
                  <div className="skeleton-left">
                    <div
                      className="line"
                      style={{ width: "40%", height: "20px" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {!dataLoading ? (
              <div className="cell">
                <div
                  onClick={(e) =>
                    setSelectedCard({
                      line1: "initial_banker_voc",
                      line2: "initial_client_voc",
                      line3: "initial_voc_difference",
                      amount: cardData?.initial_client_voc,
                      name: "Client Initial VOC",
                    })
                  }
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.initial_banker_voc, 3)}
                    </div>
                    <div className="cell-subheader">Banker Initial VOC</div>
                  </div>
                  <div>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.initial_client_voc, 3)}
                    </div>
                    <div className="cell-subheader">Client Initial VOC</div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 0.5px lightgray",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.initial_voc_difference, 3)}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cell skeleton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="skeleton-left" style={{ paddingTop: "30px" }}>
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                </div>
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                </div>
                <div
                  style={{
                    borderTop: "solid 0.5px lightgray",
                    paddingTop: "30px",
                  }}
                >
                  <div className="skeleton-left">
                    <div
                      className="line"
                      style={{ width: "40%", height: "20px" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {!dataLoading ? (
              <div className="cell">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}{" "}
                      {FormatNumber(
                        cardData?.initial_banker_voc_without_fee,
                        3
                      )}
                    </div>
                    <div className="cell-subheader">
                      Banker Initial VOC Minus Fees
                    </div>
                  </div>
                  <div>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(
                        cardData?.initial_client_voc_without_fee,
                        3
                      )}
                    </div>
                    <div className="cell-subheader">
                      Client Initial VOC Minus Fees
                    </div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 0.5px lightgray",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(
                        cardData?.initialVOC_without_fees_difference,
                        3
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cell skeleton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="skeleton-left" style={{ paddingTop: "30px" }}>
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "60%", height: "10px" }}
                  ></div>
                </div>
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "60%", height: "10px" }}
                  ></div>
                </div>
                <div
                  style={{
                    borderTop: "solid 0.5px lightgray",
                    paddingTop: "30px",
                  }}
                >
                  <div className="skeleton-left">
                    <div
                      className="line"
                      style={{ width: "40%", height: "20px" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else if (step === 1) {
      return (
        <div className="cardGroup2">
          <div className="grid">
            {!dataLoading ? (
              <div className="cell">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}{" "}
                      {FormatNumber(cardData?.interest_earned, 3)}
                    </div>
                    <div className="cell-subheader">Interest Earned</div>
                  </div>
                  <div>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.interest_paid, 3)}
                    </div>
                    <div className="cell-subheader">Interest Paid</div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 0.5px lightgray",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;{" "}
                      {FormatNumber(cardData?.historic_interest_difference, 3)}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cell skeleton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="skeleton-left" style={{ paddingTop: "30px" }}>
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                </div>
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                </div>
                <div
                  style={{
                    borderTop: "solid 0.5px lightgray",
                    paddingTop: "30px",
                  }}
                >
                  <div className="skeleton-left">
                    <div
                      className="line"
                      style={{ width: "40%", height: "20px" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {!dataLoading ? (
              <div className="cell">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}{" "}
                      {FormatNumber(cardData?.interest_receiveable, 3)}
                    </div>
                    <div className="cell-subheader">Interest Receivable</div>
                  </div>
                  <div>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.interest_payable, 3)}
                    </div>
                    <div className="cell-subheader">Interest Payable</div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 0.5px lightgray",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.future_interest_difference, 3)}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cell skeleton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="skeleton-left" style={{ paddingTop: "30px" }}>
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "60%", height: "10px" }}
                  ></div>
                </div>
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "60%", height: "10px" }}
                  ></div>
                </div>
                <div
                  style={{
                    borderTop: "solid 0.5px lightgray",
                    paddingTop: "30px",
                  }}
                >
                  <div className="skeleton-left">
                    <div
                      className="line"
                      style={{ width: "40%", height: "20px" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {!dataLoading ? (
              <div className="cell">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}{" "}
                      {FormatNumber(cardData?.banker_completed_bonds, 3)}
                    </div>
                    <div className="cell-subheader">Banker Completed Bonds</div>
                  </div>
                  <div>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.client_completed_bonds, 3)}
                    </div>
                    <div className="cell-subheader">Client Completed Bonds</div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 0.5px lightgray",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.completed_bonds_difference, 3)}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cell skeleton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="skeleton-left" style={{ paddingTop: "30px" }}>
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                </div>
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                </div>
                <div
                  style={{
                    borderTop: "solid 0.5px lightgray",
                    paddingTop: "30px",
                  }}
                >
                  <div className="skeleton-left">
                    <div
                      className="line"
                      style={{ width: "40%", height: "20px" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {!dataLoading ? (
              <div className="cell">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}{" "}
                      {FormatNumber(
                        cardData?.banker_completed_bonds_without_fees,
                        3
                      )}
                    </div>
                    <div className="cell-subheader">
                      Banker Completed Minus Fees
                    </div>
                  </div>
                  <div>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(
                        cardData?.client_completed_bonds_without_fees,
                        3
                      )}
                    </div>
                    <div className="cell-subheader">
                      Client Completed Bonds Minus Fees
                    </div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 0.5px lightgray",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}{" "}
                      {FormatNumber(
                        cardData?.completed_bonds_difference_without_fees,
                        3
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cell skeleton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="skeleton-left" style={{ paddingTop: "30px" }}>
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "60%", height: "10px" }}
                  ></div>
                </div>
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "60%", height: "10px" }}
                  ></div>
                </div>
                <div
                  style={{
                    borderTop: "solid 0.5px lightgray",
                    paddingTop: "30px",
                  }}
                >
                  <div className="skeleton-left">
                    <div
                      className="line"
                      style={{ width: "40%", height: "20px" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <div className="cardGroup3">
          <div className="grid">
            {!dataLoading ? (
              <div className="cell">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}{" "}
                      {FormatNumber(cardData?.banker_redeemed_bonds, 3)}
                    </div>
                    <div className="cell-subheader">Banker Redeemed Bonds</div>
                  </div>
                  <div>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.client_redeemed_bonds, 3)}
                    </div>
                    <div className="cell-subheader">Client Redeemed Bonds</div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 0.5px lightgray",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}{" "}
                      {FormatNumber(cardData?.redeemed_bonds_difference, 3)}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cell skeleton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="skeleton-left" style={{ paddingTop: "30px" }}>
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                </div>
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                </div>
                <div
                  style={{
                    borderTop: "solid 0.5px lightgray",
                    paddingTop: "30px",
                  }}
                >
                  <div className="skeleton-left">
                    <div
                      className="line"
                      style={{ width: "40%", height: "20px" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {!dataLoading ? (
              <div className="cell">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}{" "}
                      {FormatNumber(
                        cardData?.banker_redeemed_bonds_without_fees,
                        3
                      )}
                    </div>
                    <div className="cell-subheader">
                      Banker Redeemed Bonds Minus Fees
                    </div>
                  </div>
                  <div>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(
                        cardData?.client_redeemed_bonds_without_fees,
                        3
                      )}
                    </div>
                    <div className="cell-subheader">
                      Client Redeemed Bonds Minus Fees
                    </div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 0.5px lightgray",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}{" "}
                      {FormatNumber(
                        cardData?.redeemed_bonds_difference_without_fees,
                        3
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cell skeleton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="skeleton-left" style={{ paddingTop: "30px" }}>
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "60%", height: "10px" }}
                  ></div>
                </div>
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "60%", height: "10px" }}
                  ></div>
                </div>
                <div
                  style={{
                    borderTop: "solid 0.5px lightgray",
                    paddingTop: "30px",
                  }}
                >
                  <div className="skeleton-left">
                    <div
                      className="line"
                      style={{ width: "40%", height: "20px" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {!dataLoading ? (
              <div className="cell">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.banker_renewed_bonds, 3)}
                    </div>
                    <div className="cell-subheader">Banker Renewed Bonds</div>
                  </div>
                  <div>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(cardData?.client_renewed_bonds, 3)}
                    </div>
                    <div className="cell-subheader">Client Renewed Bonds</div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 0.5px lightgray",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}{" "}
                      {FormatNumber(cardData?.renewed_bonds_difference, 3)}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cell skeleton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="skeleton-left" style={{ paddingTop: "30px" }}>
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                </div>
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                </div>
                <div
                  style={{
                    borderTop: "solid 0.5px lightgray",
                    paddingTop: "30px",
                  }}
                >
                  <div className="skeleton-left">
                    <div
                      className="line"
                      style={{ width: "40%", height: "20px" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {!dataLoading ? (
              <div className="cell">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(
                        cardData?.banker_renewed_bonds_without_fees,
                        3
                      )}
                    </div>
                    <div className="cell-subheader">
                      Banker Renewed Bonds Minus Fees
                    </div>
                  </div>
                  <div>
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(
                        cardData?.client_renewed_bonds_without_fees,
                        3
                      )}
                    </div>
                    <div className="cell-subheader">
                      Client Renewed Bonds Minus Fees
                    </div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 0.5px lightgray",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="cell-header">
                      {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                      {FormatNumber(
                        cardData?.renewed_bonds_difference_without_fees,
                        3
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cell skeleton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="skeleton-left" style={{ paddingTop: "30px" }}>
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "60%", height: "10px" }}
                  ></div>
                </div>
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "40%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "60%", height: "10px" }}
                  ></div>
                </div>
                <div
                  style={{
                    borderTop: "solid 0.5px lightgray",
                    paddingTop: "30px",
                  }}
                >
                  <div className="skeleton-left">
                    <div
                      className="line"
                      style={{ width: "40%", height: "20px" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: window.innerHeight - 175,
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            position: "relative",
            backgroundColor: "#FAFAFA",
          }}
        >
          {conditionalCards()}
          <img
            className="arrow-left"
            style={{ cursor: "pointer", opacity: step === 0 ? 0.2 : 1 }}
            onClick={(e) => stepDown()}
            src={arrowLeft}
            alt=""
            width={31}
          />

          <img
            className="arrow-right"
            style={{ cursor: "pointer", opacity: step < 2 ? 1 : 0.2 }}
            onClick={(e) => stepUp()}
            src={arrowRight}
            alt=""
            width={31}
          />
        </div>

        <div className="grid2">
          <div
            className="mobileHeight"
            style={{
              padding: "0px 40px",
              paddingTop: "20px",
            }}
          >
            {!dataLoading ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <div className="cell-header">
                    {selectedCoin ? selectedCoin.symbol : "$"}&nbsp;
                    {FormatNumber(selectedCard?.amount, 3)}
                  </div>
                  <div className="cell-subheader">{selectedCard?.name}</div>
                </div>
                <div onClick={(e) => setFullScreen(!fullScreen)}>
                  Full Screen
                </div>
              </div>
            ) : (
              <div className="skeleton">
                <div className="skeleton-left">
                  <div
                    className="line"
                    style={{ width: "20%", height: "20px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "10%", height: "10px" }}
                  ></div>
                </div>
              </div>
            )}

            {/* <Chart selectedCard={selectedCard} /> */}
          </div>

          <div
            style={{
              border: "solid 0.5px #E7E7E7",
              borderWidth: "0px 0px 0px 1px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "20px",
                borderBottom: "solid 0.5px #E7E7E7",
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                // onClick={(e) => stepDown()}
                src={arrowLeft}
                alt=""
              />{" "}
              24 Hours
              <img
                style={{ cursor: "pointer" }}
                // onClick={(e) => stepUp()}
                src={arrowRight}
                alt=""
              />
            </div>
            {!dataLoading ? (
              <div
                style={{
                  borderBottom: "solid 0.5px #E7E7E7",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "22px",
                    fontWeight: 800,
                    color: "#010101",
                  }}
                >
                  --
                </div>
                <div style={{ fontSize: "11px", fontWeight: 400 }}>
                  Daily Average
                </div>
              </div>
            ) : (
              <div className="cell skeleton">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="line"
                    style={{ width: "30%", height: "10px" }}
                  ></div>
                  <div
                    className="line"
                    style={{ width: "40%", height: "5px" }}
                  ></div>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              {!dataLoading ? (
                <div
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "22px",
                      fontWeight: 800,
                      color: "#010101",
                    }}
                  >
                    --
                  </div>
                  <div style={{ fontSize: "11px", fontWeight: 400 }}>
                    Last 24 Hours
                  </div>
                </div>
              ) : (
                <div className="cell skeleton">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="line"
                      style={{ width: "30%", height: "10px" }}
                    ></div>
                    <div
                      className="line"
                      style={{ width: "40%", height: "5px" }}
                    ></div>
                  </div>
                </div>
              )}
              {!dataLoading ? (
                <div
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: 800,
                      color: "#0E9347",
                    }}
                  >
                    --
                  </div>
                  <div style={{ fontSize: "9px", fontWeight: 400 }}>
                    +(--)% Over The Previous 24 Hours
                  </div>
                </div>
              ) : (
                <div className="cell skeleton">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="line"
                      style={{ width: "30%", height: "8px" }}
                    ></div>
                    <div
                      className="line"
                      style={{ width: "50%", height: "4px" }}
                    ></div>
                  </div>
                </div>
              )}
              {!dataLoading ? (
                <div
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: 800,
                      color: "#CD2225",
                    }}
                  >
                    --
                  </div>
                  <div style={{ fontSize: "9px", fontWeight: 400 }}>
                    -(--)% Over The Daily Average
                  </div>
                </div>
              ) : (
                <div className="cell skeleton">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="line"
                      style={{ width: "30%", height: "8px" }}
                    ></div>
                    <div
                      className="line"
                      style={{ width: "50%", height: "4px" }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
