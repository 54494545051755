import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalContexProvider } from "./globalContex";
import ToastContextProvider from "./ToastContext";
import RegistrationContextProvider from "./RegistrationContext";
import { QueryClient, QueryClientProvider } from "react-query";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

root.render(
  <React.StrictMode>
    <RegistrationContextProvider>
    <QueryClientProvider client={queryClient}>
      <GlobalContexProvider>
        <ToastContextProvider>
          <App />
        </ToastContextProvider>
      </GlobalContexProvider>
      </QueryClientProvider>
    </RegistrationContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
