import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import * as jose from "jose";
import cloudUploadIcon from "../../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../../static/images/loading.gif";

import bonds from "../../../static/images/sidebarIcons/bonds.svg";

import "./newBondTier.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";
import clearInput from "../../../static/images/icons/refresh.svg";

import { GlobalContex } from "../../../globalContex";
import BondAssetList from "./BondAssetList";
import BondTemplate from "./BondTemplate";
// import { useBankerDetails } from "../../../queryHooks";
// import jwt from "jsonwebtoken";
// import { sign } from "react-native-pure-jwt";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function NewSip({ step, setStep, loading, setLoading,setMainMenu }) {
  const navigate = useNavigate();

  const {
    loginData,
    bankerEmail,
    allBankers,
    tabs,
    setTabs,
    selectedTab,
    setSelectedTab,
    setGlobalMenuAdd,
    FormatCurrency,
    FormatNumber,
    allCoins,
    refetchBonds,
    setRefetchBonds,
    tabSelected,
    setTabSelected,
    bankerMenu,
  } = useContext(GlobalContex);

  // Form Variables
  const [icon, setIcon] = useState("");
  const [tierName, setTierName] = useState("");
  const [tierDesc, setTierDesc] = useState("");
  const [banker, setBanker] = useState();
  const [tierType, setTierType] = useState("buy");
  const [app, setApp] = useState();

  const [bankerTag, setBankerTag] = useState("");

  const [iconLoading, setIconLoading] = useState(false);

  const [bondType, setBondType] = useState(null);

  const [bondCollectionApp, setBondCollectionApp] = useState(null);
  const [bondPayoutApp, setBondPayoutApp] = useState(null);
  const [costOneBond, setCostOneBond] = useState("");
  const [bondLength, setBondLength] = useState("");
  const [dailyIntRate, setDailyIntRate] = useState("");
  const [affFee, setAffFee] = useState("");
  const [redemptionFee, setRedemptionFee] = useState("yes");
  const [wholesaleBond, setWholesaleBond] = useState(null);
  const [connectABond, setConnectABond] = useState(false);

  const [bondError, setBondError] = useState("");

  const [bondTemplate, setBondTemplate] = useState(null);
  const [bondAsset, setBondAsset] = useState(null);
  const [sipCost, setSipCost] = useState("");
  const [sipLength, setSipLength] = useState("");
  const [sipIntRate, setSipIntRate] = useState("");

  useEffect(() => {
    localStorage.setItem("step", step);
  }, [step]);

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";

    const jwts = await signJwt(fileName, emailDev, secret);
    console.log(jwts, "lkjkswedcf");
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  const handleReplaceTab = () => {
    const item = {
      menuName: "Bonds",
      menuIcon: bonds,
      enabled: true,
    };
    if (
      tabs.findIndex((o) => o.menuName === item.menuName) < 0 ||
      tabs.length < 1
    ) {
      const index = tabs.findIndex((o) => o.menuName === selectedTab.menuName);
      console.log(
        tabs.findIndex((o) => o.menuName === selectedTab.menuName),
        selectedTab,
        "jhwgjwhe"
      );
      tabs.splice(index, 1, item);
      setSelectedTab(tabs.length < 1 ? tabs[index] : item);
    } else {
      setSelectedTab(item);
    }
  };

  function getContent() {
    switch (step) {
      // case "Success":
      //   setTimeout(() => {
      //     // navigate("/ventures/Brands");
      //     window.location.reload();
      //   }, 2000);
      //   return (
      //     <div className="newConglomerate">
      //       <div className="succesView">
      //         <div className="labelItm">
      //           You Have Successfully Created a new “{bondAsset} Bond”. You Will
      //           Be Redirected To The Updated Bond List Automatically
      //         </div>
      //       </div>
      //     </div>
      //   );

      case "Success":
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm" style={{ textAlign: "center" }}>
                You Have Successfully Created a New “{bondAsset?.coinName} SIP”.
                You Will Be Redirected To The Updated SIP List Automatically
              </div>
            </div>
          </div>
        );

      case "bondAddFailware":
        setTimeout(() => {
          // navigate("/ventures/Brands");
          setGlobalMenuAdd(false);
        }, 2000);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm" style={{ textAlign: "center" }}>
                Sip Creation Failed.
              </div>
            </div>
          </div>
        );

      case "bondTemplate":
        return (
          <>
            <div
              className="newConglomerate"
              style={{ paddingBottom: "0px", paddingTop: "0px" }}
            >
              <BondTemplate
                bondAsset={bondAsset}
                bondTemplate={bondTemplate}
                setBondTemplate={setBondTemplate}
                onClose={() => setStep("")}
              />
            </div>
            <div>
              <div className="fullBtn" onClick={() => setStep("")}>
                Go Back
              </div>
              {/* <div className="btnSubmit" onClick={() => onClose()}>
          Submit BondTier
        </div> */}
            </div>
          </>
        );
      case "bondAsset":
        return (
          <>
            <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
              <BondAssetList
                setBondAsset={setBondAsset}
                onClose={() => setStep("")}
              />
            </div>
            <div>
              <div className="fullBtn" onClick={() => setStep("")}>
                Go Back
              </div>
              {/* <div className="btnSubmit" onClick={() => onClose()}>
            Submit BondTier
          </div> */}
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="newConglomerate">
              <Scrollbars
                className="scrollForm"
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
              >
                {/* Select Bond Asset */}
                <div className="name">Select Bond Asset</div>
                {bondAsset ? (
                  <div
                    className="user"
                    onClick={() => setStep("bondAsset")}
                  // onClick={() => setStep("banker")}
                  >
                    <img className="dp" src={bondAsset?.coinImage} alt="" />
                    <div className="userDetail">
                      <div className="name">{bondAsset?.coinName}</div>
                      <div className="email">{bondAsset?.coinSymbol}</div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => setStep("bondAsset")}
                  >
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* Select Connected Bond Template */}
                <div className="name">Select Connected Bond Template</div>
                {bondTemplate ? (
                  <div
                    onClick={() => setStep("bondTemplate")}
                    className="user"
                    style={{
                      fontSize: "22px",
                      fontWeight: 700,
                      display: "block",
                      padding: "0px",
                    }}
                  >
                    <div
                      key={bondTemplate?._id}
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        height: "100px",
                        padding: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={
                              allCoins?.find(
                                (o) =>
                                  o?.coinSymbol ===
                                  bondTemplate?.coinsData[0]?.coin
                              )?.coinImage
                            }
                            alt=""
                            style={{
                              borderRadius: "50%",
                              width: "22px",
                              height: "22px",
                            }}
                          />
                          <div style={{ paddingLeft: "10px" }}>
                            {
                              allCoins?.find(
                                (o) =>
                                  o?.coinSymbol ===
                                  bondTemplate?.coinsData[0]?.coin
                              )?.coinName
                            }
                          </div>
                        </div>

                        <input type="checkbox" />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingTop: "10px",
                        }}
                      >
                        <div>
                          {bondTemplate?.coinsData[0]?.bondCost}&nbsp;
                          {bondTemplate?.coinsData[0]?.coin}
                        </div>
                        <div>
                          {bondTemplate?.monthly_interest_rate?.toFixed(4)}
                          %/Month
                        </div>
                        <div>{bondTemplate?.days} Days</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => {
                      if (bondAsset) {
                        setStep("bondTemplate");
                      }
                    }}
                  >
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* What Is The Cost Of One SIP  */}

                <div className="name">What Is The Cost Of One SIP</div>
                <div className="inputWrap">
                  <input
                    value={sipCost}
                    onChange={(e) => setSipCost(e.target.value)}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="text"
                    placeholder="0.0000"
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      width: "100px",
                      fontSize: "16px",
                    }}
                  >
                    {bondAsset ? bondAsset.coinSymbol : "BTC"}
                  </div>
                </div>

                {/* What Is The SIP Length  */}

                <div className="name">What Is The SIP Length</div>
                <div className="inputWrap">
                  <input
                    value={sipLength}
                    onChange={(e) => setSipLength(e.target.value)}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="text"
                    placeholder="Enter Days..."
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      width: "100px",
                      fontSize: "16px",
                    }}
                  >
                    Months
                  </div>
                </div>

                {/* What Is The Monthly Interest Rate  */}

                <div className="name">What Is The Monthly Interest Rate</div>
                <div className="inputWrap">
                  <input
                    value={sipIntRate}
                    onChange={(e) => setSipIntRate(e.target.value)}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    max="100"
                    className="text"
                    placeholder="0.00"
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      width: "160px",
                      fontSize: "16px",
                    }}
                  >
                    % Per Month
                  </div>
                </div>

                <div className="space"></div>
              </Scrollbars>
            </div>
            {/* <div
              className="footerBtns"
              style={{
                paddingTop: 0,
                position: "fixed",

                bottom: "0px",
                // left: "395px",
                right: "0px",
                width: "500px",
              }}
            >
              <div
                className="btnSave"
                style={{
                  borderLeft: "0.5px solid #E7E7E7",
                  borderRight: "0.5px solid #E7E7E7",
                  opacity: 0.4,
                }}
                // onClick={() => setSubStep("")}
              >
                Go Back
              </div>
              <div className="btnSave">Next Step</div>
            </div> */}
            {/* <div>
              <div className="fullBtn" onClick={() => validate(true)}>
                <span
                  style={{
                    opacity:
                      bondType &&
                      bondAsset &&
                      bondCollectionApp &&
                      bondPayoutApp &&
                      costOneBond &&
                      bondLength &&
                      dailyIntRate &&
                      affFee
                        ? 1
                        : 0.3,
                  }}
                >
                  Create Bond
                </span>
              </div>
            </div> */}
            <div className="footerBtns">
              <div className="newField"
                style={{ fontWeight: 700 }}
                onClick={(e) => {
                  setStep("");
                  setMainMenu("");
                }}>
                Go Back
              </div>
              <div className="btnSubmit" onClick={() => validate(true)}>
                Create SIP
              </div>
            </div>
          </>
        );
    }
  }

  useEffect(() => {
    console.log(wholesaleBond, "ljwedlwed");
  }, [wholesaleBond]);

  const createNewBond = () => {
    setLoading(true);

    axios
      .post(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/create`,
        {
          email: bankerEmail,
          token: loginData?.idToken,
          bond_type: "fixed",
          coinsData: [
            {
              coin: bondAsset?.coinSymbol,
              bondCost: costOneBond,
            },
          ],
          days: bondLength,
          daily_interest_rate: dailyIntRate,
          payoutProfileId: bondPayoutApp?.profile_id,
          payinProfileId: bondCollectionApp?.profile_id,
          connectedBondEnabled: connectABond,
          connectedBond_templateId: wholesaleBond?.bond_template_id,
          remove_redemption_fee: redemptionFee === "yes" ? false : true,
          bond_upline_fee: affFee,
        }
      )
      .then(({ data }) => {
        if (data.status) {
          setStep("Success");
          setTimeout(() => {
            setGlobalMenuAdd(false);
            setRefetchBonds(!refetchBonds);
            if (tabSelected !== "Offerings") {
              console.log(tabSelected, "lkwnlwkefe");
              handleReplaceTab(bankerMenu.find((o) => o.menuName === "Bonds"));
              setTabSelected("Offerings");
            }

            // navigate("/ventures/Brands");
            // window.location.reload();
          }, 2000);
        } else if (!data.status && data.message === "jwt expired") {
          setStep("Token Expired");
        } else {
          setStep("bondAddFailware");
          toast.success(data.message || "API Error");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setBondError(error?.response?.data?.message);
        setStep("bondAddFailware");
      })
      .finally(() => setLoading(false));

    // axios
    //   .post(
    //     `https://comms.globalxchange.io/coin/iced/banker/custom/bond/user/list/create`,
    //     {
    //       email: banker.email, // BANKER EMAIL
    //       token: loginData.idToken, // BANKER AUTH TOKEN
    //       name: tierName,
    //       app_code: app.app_code,
    //       listType: tierType, // buy / visible
    //       icon: icon,
    //       description: tierDesc,
    //     }
    //   )
    //   .then(({ data }) => {
    //     if (data.status === false) {
    //       toast.success(data.message || "API Error");
    //     } else setStep("success");
    //   })
    //   .catch((error) => {
    //     toast.error(error?.response?.data?.message);
    //   })
    //   .finally(() => setLoading(false));
  };

  const createSIP = () => {
    setLoading(true);
    axios
      .post(
        `https://comms.globalxchange.io/retired/block/banker/create/blocktemplate`,
        {
          email: bankerEmail,
          token: loginData?.idToken,
          bond_template_id: bondTemplate?.bond_template_id,
          coin: bondAsset?.coinSymbol,
          retirement_block: sipCost,
          month_interest_rate: sipIntRate,
          period: sipLength,
        }
      )
      .then(({ data }) => {
        if (data.status) {
          setStep("Success");
          setTimeout(() => {
            setGlobalMenuAdd(false);
            setRefetchBonds(!refetchBonds);
            if (tabSelected !== "Offerings") {
              console.log(tabSelected, "lkwnlwkefe");
              handleReplaceTab(bankerMenu.find((o) => o.menuName === "Bonds"));
              setTabSelected("Offerings");
            }

            // navigate("/ventures/Brands");
            // window.location.reload();
          }, 2000);
        } else if (!data.status && data.message === "jwt expired") {
          setStep("Token Expired");
        } else {
          setStep("bondAddFailware");
          toast.success(data.message || "API Error");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setBondError(error?.response?.data?.message);
        setStep("bondAddFailware");
      })
      .finally(() => setLoading(false));
  };

  function validate() {
    if (bondTemplate && bondAsset && sipCost && sipLength && sipIntRate) {
      createSIP();
    } else {
      toast.warning("All Fields Are Mandatory");
    }
  }

  return (
    <>
      {/* {getContent()} */}
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <div className="loader"></div>
          <div style={{ padding: "20px", fontWeight: 600, color: "#010101" }}>
            Creating New Bond ...
          </div>
        </div>
      ) : (
        getContent()
      )}
    </>
  );
}

export default NewSip;
