import React, { useContext, useEffect, useState } from "react";
import "../AddNewCategory/category.scss";
import { GlobalContex } from "../../globalContex";
import NavBar from "../../globalComponents/NavBar";
import axios from "axios";
import cloudUploadIcon from "../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../static/images/loading.gif";
import CloseIcon from "../../static/images/close.svg"
import { ToastContainer, toast } from "react-toastify";
import defaultImg from "../../static/images/icons/app_placeholder.png";
import { useLocation, useNavigate } from "react-router-dom";




const AddNewCoupon = () => {
    const tabs = ["Add Coupon"];
    const [name, setName] = useState("")
    const [apiLoading, setApiLoading] = useState(false)
    const [username, setUserName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [email, setEmail] = useState("")
    const [coupon, setCoupon] = useState("")
    const [description, setDescription] = useState("")
    const [selectedQualification, setSelectedQualification] = useState("")
    const [minCouponValue, setMinCouponValue] = useState("")
    const [searchProducts, setSearchProducts] = useState("")
    const [selectType, setSelectType] = useState("")
    const [couponValue, setCouponValue] = useState("")
    const [allProducts, setAllProducts] = useState([])
    const [productsArray, setProductsArray] = useState([])
    const navigate = useNavigate();

    let qualificationData = [
        {
            key: "product", value: "On select products"
        },
        {
            key: "ordervolume", value: "If the user achieves a minimum order value"
        }
    ]

    let couponTypeData = ["Percentage", "Flat"]



    const { tabSelected, setTabSelected, setAddNewField, couponTrigger, setCouponTrigger, selectedBusiness } = useContext(GlobalContex);

    useEffect(() => {
        setTabSelected("Add Coupon");
        console.log(selectedBusiness?._id, "businessDetail")
        axios.get(
            `https://frissly.apimachine.com/api/product?businessusername=${selectedBusiness?.businessusername}`
        ).then(({ data }) => {
            if (data.status) {
                setAllProducts(data.data);
            }
        });
    }, []);

    useEffect(() => {
        if (couponTrigger) {
            if (name) {
                setApiLoading(true)
                let body = {
                    name: name,
                    couponCode: coupon,
                    description: description,
                    businessusername: selectedBusiness?.businessusername,
                    qualificationType: selectedQualification,
                    orderVolume: selectedQualification === "ordervolume" ? minCouponValue : "",
                    productId: selectedQualification === "product" ? productsArray : [],
                    discountType: selectType?.toLowerCase(),
                    discountValue: couponValue
                }
                console.log(body, "couponBody")
                if (body.name || body.couponCode) {
                    axios.post("https://frissly.apimachine.com/api/coupon/create", body)
                        .then(({ data }) => {
                            if (data.status) {
                                toast.success("Coupon Added Successfully")
                                setTimeout(() => {
                                    setAddNewField(false)
                                    navigate("/Admin")
                                }, 1500)
                            } else {
                                toast.error(data?.message || "Something went wrong")
                            }
                        })
                        .catch((err) => {
                            toast.error("API Error");
                        })
                }
                setApiLoading(false)
            }
        }
        setCouponTrigger(false)
    }, [couponTrigger])





    return (
        <>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false, true]}
                apiLoading={apiLoading}
                setApiLoading={setApiLoading}
            />
            <div className="formDisplayData">
                <div>
                    <div className="basicForm">
                        <p className="categoryTitle">Basic Details</p>
                        <p className="categoryName">Name</p>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="inputStyle" placeholder="Example: SWIGGY12" />
                        <p className="categoryName topmargin">Description</p>
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} rows="6" className="inputStyle" placeholder="Example: SWIGGY12" ></textarea>
                        <p className="categoryName topmargin">Coupon code</p>
                        <input type="text" value={coupon} onChange={(e) => setCoupon(e.target.value)} className="inputStyle" placeholder="Example: SWIGGY12" />
                    </div>
                    <br />
                    <div className="basicForm">
                        <p className="categoryTitle">Qualification details</p>
                        <p className="categoryName">When does this coupon apply</p>
                        {qualificationData.map((item) => {
                            return <div onClick={() => setSelectedQualification(item.key)}>
                                <div style={{ border: selectedQualification === item.key ? "1px solid #E92729" : "" }} className="boxStyle">{item.value}</div>
                                <br />
                            </div>
                        })}
                        <br />
                        {selectedQualification === "ordervolume" ?
                            <div>
                                <p className="categoryName topmargin">What is the minimum order value</p>
                                <input type="text" value={minCouponValue} onChange={(e) => setMinCouponValue(e.target.value)} className="inputStyle" placeholder="0.00" />
                            </div>
                            : <div></div>}
                        {selectedQualification === "product" ?
                            <>
                                <p className="categoryName topmargin">Select the products that this coupon applies to</p>
                                <input type="text" value={searchProducts} onChange={(e) => setSearchProducts(e.target.value)} className="inputSearchStyle" placeholder="Search product name.." />
                                <div className="productsBox">
                                    {allProducts.filter(
                                        (item) =>
                                            item?.name
                                                ?.toLowerCase()
                                                .includes(searchProducts.toLowerCase())).map((item) => {
                                                    return <div onClick={() => productsArray.includes(item?._id) ? setProductsArray(productsArray.filter(field => field !== item?._id)) : setProductsArray([...productsArray, item?._id])}>
                                                        <div className="productsData" style={{ background: productsArray.includes(item?._id) ? "#E92729" : "" }}>
                                                            <img
                                                                src={item?.icon}
                                                                alt=""
                                                                className="imageProducts"
                                                            />
                                                            <div style={{ marginLeft: "10px" }}>
                                                                <div className="searchBoxTitle" style={{ color: productsArray.includes(item?._id) ? "white" : "" }}>{item?.name} &nbsp;</div>
                                                                <div className="searchBoxSubTitle" style={{ color: productsArray.includes(item?._id) ? "white" : "" }}>{item?._id}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                </div>
                            </>
                            : <div></div>}
                    </div>
                </div>
                <div>
                    <div className="basicForm">
                        <p className="categoryTitle">Discount details</p>
                        <p className="categoryName">Select type of discount</p>
                        {couponTypeData.map((item) => {
                            return <div onClick={() => setSelectType(item)}>
                                <div style={{ border: selectType === item ? "1px solid #E92729" : "" }} className="boxStyle">{item}</div>
                                <br />
                            </div>
                        })}
                        <br />
                        <div>
                            <p className="categoryName topmargin">What is the value of the discount</p>
                            <input type="text" value={couponValue} onChange={(e) => setCouponValue(e.target.value)} className="inputStyle" placeholder="0.00" />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
};

export default AddNewCoupon;