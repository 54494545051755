import React, { useContext, useState } from "react";
// import "../../../../../static/scss/subdrawer.scss";
// import "./subdrawer.module.scss"
// import "./subdrawer.scss"
import close from "../../../../../static/images/icons/close.svg";
import LoadingAnimation from "../../../../../globalComponents/LoadingAnimation";
import PurpleClose from "../../../../../static/images/purpleClose.svg"
import lock from "../../../../../static/images/icons/lock.svg";
import Axios from "axios";
import * as jose from "jose";
import loadingGif from "../../../../../static/images/loading.gif";
import cloudUploadIcon from "../../../../../static/images/clipIcons/cloudUpload.svg";
import uploadGroupIcon from "../../../../../static/images/uploadGroupIcon.svg"
import { ToastContainer, toast } from "react-toastify";
import { GlobalContex } from "../../../../../globalContex";
import { useEffect } from "react";

function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.


const CityDrawer = () => {
    const [step, setStep] = useState("default")
    const [name, setName] = useState("");
    const [username, setUsername] = useState("")
    const [bio, setBio] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [twitter, setTwitter] = useState("");
    const [country, setCountry] = useState("");
    const [colouredIcon, setColouredIcon] = useState("");
    const [colouredIconLoading, setColouredIconLoading] = useState("");
    const [path, setPath] = useState([]);
    const [successMsg, setSuccessMsg] = useState("")
    const [loadingtext, setloadingtext] = useState("")
    const [allCategories, setAllCategories] = useState([])
    const [cateNum, setCateNum] = useState(0)
    const [description, setDescription] = useState("")
    const [gstCode, setGSTcode] = useState("")
    const [shortForm, setShortForm] = useState("")
    const [allStates, setAllStates] = useState([])


    const { bankerEmail, setTabSelected, cityDrawerOpen, SetCityDrawerOpen,
        selectedCity, setSelectedCity, login, loginData,
        refetchProduct, setRefechProduct, productDrawerOpen, setProductsDrawerOpen, selectedBusiness, selectedStatus, setSelectedStatus,
        statusValues, selectedState, setSelectedState } = useContext(GlobalContex);

    useEffect(() => {
        setStep("Action");
        setPath(["Action"]);
    }, [cityDrawerOpen]);

    // useEffect(() => {
    //   console.log(ActionBtn, "ActionBtn")
    //   if (ActionBtn === "Actions") {
    //     setStep("Actions")
    //   } else{
    //     setStep("default")
    //   }
    // }, [step, ActionBtn])

    useEffect(() => {
        setStep(path[path.length - 1]);
    }, [path]);

    const signJwt = async (fileName, emailDev, secret) => {
        try {
            const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
                .setProtectedHeader({ alg: "HS512" })
                .setIssuer("gxjwtenchs512")
                .setExpirationTime("10m")
                .sign(new TextEncoder().encode(secret));
            return jwts;
        } catch (error) {
            console.log(error, "kjbedkjwebdw");
        }
    };

    const handleBreadcrumbClick = (clickedStep) => {
        const foundStepIndex = path.findIndex((o) => o === clickedStep);
        const tempPath = path;
        tempPath.splice(foundStepIndex + 1, tempPath.length);
        setPath([...tempPath]);
        console.log(path, "lwndwed");
    };

    // const uploadImage = async (e, setImage, setLoading) => {
    //   setLoading(true);
    //   const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
    //     e.target.files[0].name.lastIndexOf(".")
    //   )}`;
    //   const formData = new FormData();
    //   const file = renameFile(e.target.files[0], fileName);
    //   formData.append("files", file);
    //   const path_inside_brain = "root/";

    //   const jwts = await signJwt(fileName, emailDev, secret);
    //   console.log(jwts, "lkjkswedcf");
    //   let { data } = await Axios.post(
    //     `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
    //     formData,
    //     {
    //       headers: {
    //         "Access-Control-Allow-Origin": "*",
    //       },
    //     }
    //   );
    //   setImage(data.payload.url);
    //   setLoading(false);
    // };

    const uploadImage = async (e, setImage, setLoading) => {
        setLoading(true);
        const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
            e.target.files[0].name.lastIndexOf('.')
        )}`;
        const formData = new FormData();
        const file = renameFile(e.target.files[0], fileName);
        formData.append('files', file);
        const path_inside_brain = 'root/';

        const jwts = await signJwt(fileName, emailDev, secret);
        // console.log(jwts, 'lkjkswedcf');
        let { data } = await Axios.post(
            `https://insurance.apimachine.com/insurance/general/upload`,
            formData,
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }
        );
        console.log(data[0].urlName, 'drive uploaded img');
        setImage(data[0].urlName);
        setLoading(false);
    };






    const deleteCity = () => {
        setStep("loading")
        setloadingtext(`Deleting ${selectedCity?.name}...`)
        Axios.delete(`https://frissly.apimachine.com/api/business/logistics/delete/city/${selectedCity?._id}`)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully deleted ${selectedCity?.name}. You will now be redirected to your updated list.`)
                    setTimeout(() => {
                        setTabSelected("Cities")
                        setStep("default");
                        SetCityDrawerOpen(false);
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }
    const updateCity = (item) => {
        let body
        if (name) {
            body = {
                name: name
            }
        }
        if (description) {
            body = {
                description: description
            }
        }
        if (colouredIcon) {
            setColouredIconLoading(true)
            body = {
                icon: colouredIcon
            }
        }
        if (shortForm) {
            body = {
                shortForm: shortForm
            }
        }
        if (item) {
            body = {
                stateId: item?._id
            }
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating State`)
        Axios.put(`https://frissly.apimachine.com/api/business/logistics/update/city/${selectedCity?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully updated data for ${selectedCity?.name}. You will now be redirected to your updated list.`)
                    setTimeout(() => {
                        setTabSelected("Cities")
                        setStep("default");
                        SetCityDrawerOpen(false);
                        setName("")
                        setDescription("")
                        setShortForm("")
                        setColouredIcon("")
                        setColouredIconLoading(false)
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const getAllStates = () => {
        Axios.get(`https://frissly.apimachine.com/api/business/logistics/get/state?businessId=${selectedBusiness?._id}`)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status) {
                    setAllStates(data.data)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const fullHeightDrawer = (message) => {
        if (message) {
            return (
                <div
                    style={{
                        height: window.innerHeight - 150,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                            marginBottom: "20px",
                        }}
                    >
                        {message}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        height: window.innerHeight - 123,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                        }}
                    >
                        Updating Icon...
                    </div>
                </div>
            );
        }
    };


    const conditionalSteps = () => {
        switch (step) {
            case "loading":
                return fullHeightDrawer(loadingtext);
            case "Token Expired":
                return (
                    <>
                        <div className="sidebarTitle">Token Expired. Login Again.</div>
                        <div
                            className="sidebarCard"
                            onClick={(e) => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            <img src={lock} alt="" style={{ width: "20px" }} />
                            <div style={{ paddingLeft: "10px" }}>Logout</div>
                        </div>
                    </>
                );
            case "success":
                return (
                    <div
                        className="assetDispText"
                        style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "60vh",
                            padding: "0px 20px",
                        }}
                    >
                        {successMsg}
                    </div>
                );

            case "Edit Product":
                return (
                    <>
                        <div className="sidebarTitle">What element do you want to edit?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Icon");
                                    setPath([...path, "Icon"]);
                                }}
                            >
                                {/* <img
              src={editPub}
              alt=""
              className="coin"
              style={{ width: "25px", height: "25px" }}
            /> */}
                                <div>Icon</div>
                            </div>
                        </div>
                    </>
                )
            case "Update Icon":
                return (
                    <>
                        <div className="productScrollDrawer">
                            {/* <div className="storyname" style={{ paddingTop: "20px" }}>
            Name
          </div> */}
                            <div>
                                <div className="storyname">Current Icon</div>

                                <div
                                    className="filesUpload ImgBox"
                                    style={{ justifyContent: "flex-start", width: "100%", height: "250px" }}
                                >
                                    <div className="fileInp icon flexProduct">
                                        <img
                                            className={`${Boolean(colouredIcon)}`}
                                            src={
                                                selectedCity?.icon

                                            }
                                            alt=""
                                            style={{ width: "95%", height: "95%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="storyname">New Icon</div>

                                <div
                                    className="filesUpload ImgBox"
                                    style={{ justifyContent: "flex-start", width: "100%" }}
                                >
                                    <label className="fileInp icon flexProduct">
                                        <img
                                            className={`${Boolean(colouredIcon)}`}
                                            src={
                                                colouredIconLoading
                                                    ? loadingGif
                                                    : colouredIcon || uploadGroupIcon
                                            }
                                            alt=""
                                            style={{ width: "100px" }}
                                        />
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                uploadImage(
                                                    e,
                                                    setColouredIcon,
                                                    setColouredIconLoading
                                                );
                                            }}
                                            accept="image/*"
                                        />

                                        <div className="hovTxt">
                                            Upload
                                            <br />
                                            New
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="bottomSubmit" style={{ background: "#E92729" }} onClick={() => updateCity()}>
                                <p className="btm-text">Submit</p>
                            </div>
                            <div className="bottomSubmit" style={{ bottom: "10px", background: "white", border: "1px solid #E7E7E7" }} onClick={() => {
                                setStep("Action");
                                setPath(["Action"]);
                            }}>
                                <p className="btm-text" style={{ color: "black" }}>Go Back</p>
                            </div>
                        </div>
                    </>
                );
            // case "Update Parent Category":
            //     return (
            //         <>
            //             <div className="sidebarTitle">Select new parent category</div>
            //             <div className="sidebarScroll">
            //                 {allCategories.map((item, index) => {
            //                     return <div id={index}
            //                         className="sidebarCard"
            //                         onClick={() => updateParentCategory(item)}
            //                     >
            //                         <div>{item?.name}</div>
            //                     </div>
            //                 })}
            //             </div>
            //         </>
            //     )
            case "Update Description":
                return (
                    <>
                        <div className="sidebarTitle">New Description</div>
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} rows="6" className="inputStyle" placeholder="Add Description.." ></textarea>
                        <div className="bottomSubmit" style={{ background: "#E92729" }} onClick={() => updateCity()}>
                            <p className="btm-text">Submit</p>
                        </div>
                        <div className="bottomSubmit" style={{ bottom: "10px", background: "white", border: "1px solid #E7E7E7" }} onClick={() => {
                            setStep("Action");
                            setPath(["Action"]);
                        }}>
                            <p className="btm-text" style={{ color: "black" }}>Go Back</p>
                        </div>
                    </>
                )
            case "Update State":
                return (
                    <>
                        <div className="sidebarTitle">Select new state</div>
                        <div className="sidebarScroll">
                            {allStates.filter(state => state?._id !== selectedCity?.stateId).map((item, index) => {
                                return <div id={index}
                                    className="sidebarCard"
                                    onClick={() => updateCity(item)}
                                >
                                    <div>{item?.name}</div>
                                </div>
                            })}
                        </div>
                    </>
                )
            case "Update City Name":
                return (
                    <>
                        <div className="sidebarTitle">New City Name</div>
                        <div
                        >
                            <input type="text" placeholder="Enter City Name..." className="inputStyle" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="bottomSubmit" style={{ background: "#E92729" }} onClick={() => updateCity()}>
                            <p className="btm-text">Submit</p>
                        </div>
                        <div className="bottomSubmit" style={{ bottom: "10px", background: "white", border: "1px solid #E7E7E7" }} onClick={() => {
                            setStep("Action");
                            setPath(["Action"]);
                        }}>
                            <p className="btm-text" style={{ color: "black" }}>Go Back</p>
                        </div>
                    </>
                )
            case "Update ShortForm":
                return (
                    <>
                        <div className="sidebarTitle">New ShortForm</div>
                        <div
                        >
                            <input type="text" placeholder="Enter ShortForm..." className="inputStyle" value={shortForm} onChange={(e) => setShortForm(e.target.value)} />
                        </div>
                        <div className="bottomSubmit" style={{ background: "#E92729" }} onClick={() => updateCity()}>
                            <p className="btm-text">Submit</p>
                        </div>
                        <div className="bottomSubmit" style={{ bottom: "10px", background: "white", border: "1px solid #E7E7E7" }} onClick={() => {
                            setStep("Action");
                            setPath(["Action"]);
                        }}>
                            <p className="btm-text" style={{ color: "black" }}>Go Back</p>
                        </div>
                    </>
                )
            case "Delete City":
                return (
                    <>
                        <div className="sidebarTitle">Are you sure you want to delete this City?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={deleteCity}
                            >
                                <div>Yes delete it</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Action");
                                    setPath(["Action"]);
                                }}
                            >
                                <div>Never mind</div>
                            </div>
                        </div>
                    </>
                )
            default:
                return (
                    <>
                        <div className="sidebarTitle">What do you want to do?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update City Name");
                                    setPath([...path, "Update City Name"]);
                                }}
                            >
                                <div>Update City Name</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Icon");
                                    setPath([...path, "Update Icon"]);
                                }}
                            >
                                <div>Update Icon</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Description");
                                    setPath([...path, "Update Description"]);
                                }}
                            >
                                <div>Update Description</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update ShortForm");
                                    setPath([...path, "Update ShortForm"]);
                                }}
                            >
                                <div>Update ShortForm</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update State");
                                    setPath([...path, "Update State"]);
                                    getAllStates()
                                }}
                            >
                                <div>Update State</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Delete City");
                                    setPath([...path, "Delete City"]);
                                }}
                            >
                                <div>Delete City</div>
                            </div>
                        </div>
                    </>
                )
        }
    }



    return (
        <div
            className={cityDrawerOpen ? "right-drawer-visible" : "right-drawer-hidden"}
            style={{ height: window.innerHeight - 123, top: 132 }}
        >
            <div
                style={{
                    padding: "30px",
                    height: "90px",
                }}
            >
                {
                    step === "loading" ||
                        step === "Token Expired" ||
                        step == "success" ? (
                        ""
                    ) : (
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div>
                                        <div style={{ fontSize: "22px" }}>
                                            {selectedCity?.name}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div
                                        className="backButton icon-imgs colorImg"
                                        onClick={(e) => {
                                            setPath([]);
                                            SetCityDrawerOpen(false);
                                        }}
                                        style={{
                                            marginLeft: "20px",
                                        }}
                                    >
                                        <img src={PurpleClose} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div
                                className="breadcrumb"
                                style={{ display: "flex", flexDirection: "row" }}
                            >
                                {path?.map((item, index) => {
                                    return (
                                        <div>
                                            {index !== 0 ? <span>&nbsp;{`->`}&nbsp;</span> : ""}
                                            <span
                                                onClick={(e) => handleBreadcrumbClick(item)}
                                                className={
                                                    index === path.length - 1 ? "crumbSelected" : "crumbs"
                                                }
                                            >
                                                {item}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                {conditionalSteps()}
                <ToastContainer />
            </div>
        </div>
    );
};

export default CityDrawer;
