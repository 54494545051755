import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import * as jose from "jose";
import cloudUploadIcon from "../../../static/images/clipIcons/cloudUpload.svg";

import loadingGif from "../../../static/images/loading.gif";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAllergies, faCaretDown } from "@fortawesome/free-solid-svg-icons";

// import LoadingAnimation from "../LoadingAnimation";
import { useNavigate } from "react-router-dom";
// import { selectLoginData } from "../../app/loginSlice";
import { toast,ToastContainer } from "react-toastify";
import axios from "axios";
import clearInput from "../../../static/images/icons/refresh.svg";
import SelectCourse from "./selectCourse";
// import AppsList from "./AppsList";
// import "./numberInput.css";
import { GlobalContex } from "../../../globalContex";

import plus from "../../../static/images/globaldrawer/plus.svg";

// import AppList from "./AppsList";
import LoadingAnimation from "../../LoadingAnimation";
// import FontList from "./FontList";


const NewCourseSection = ({
    step,
    setStep,
    setMainMenu,
    loading,
    setLoading,
}) => {
    const navigate = useNavigate();

    const {
        loginData,
        bankerEmail,
        refetchAppData,
        setRefetchAppData,
        globalMenuAdd,
        setGlobalMenuAdd,
        tabSelected,
        setTabSelected,
        setSlider,
        refetchCourses,
        setRefetchCourses
    } = useContext(GlobalContex);


    //Publishers New Publication
    const [name, setName] = useState("");
    const [app, setApp] = useState("");
    const [description, setDescription] = useState("");
    const [orderNum, setOrderNum] = useState("");
    const [visibleSubmit, setVisibleSubmit] = useState(false);
    const [selectCourse, setSelectCourse] = useState("")

    useEffect(() => {
        localStorage.setItem("step", step);
    }, [step]);

    function getContent() {
        switch (step) {
            case "success":
                return (
                    <div className="newConglomerate">
                        <div className="succesView">
                            <div className="labelItm" style={{ textAlign: "center" }}>
                                You Have Successfully Created the app {selectCourse.name}. You Will Be
                                Redirected To The Updated Courses List Automatically
                            </div>
                        </div>
                    </div>
                );
            //   case "app":
            //     return (
            //       <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
            //         <AppList setApp={setApp} onClose={() => setStep("")} />
            //       </div>
            //     );
            case "selectcourse":
                return (
                    <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
                        <SelectCourse setSelectCourse={setSelectCourse} onClose={() => setStep("")} />
                    </div>
                );
            //   case "Font":
            //     return (
            //       <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
            //         <FontList setTextFont={setTextFont} onClose={() => setStep("")} />
            //       </div>
            //     );

            case "Token Expired":
                return (
                    <>
                        <div className="newConglomerate">
                            <div className="succesView">
                                <div className="labelItm" style={{ textAlign: "center" }}>
                                    Token Expired. Login Again.
                                </div>
                            </div>
                        </div>

                        <div className="footerBtns">
                            <div
                            // onClick={(e) => handleBackStep()}
                            >
                                Go Back
                            </div>
                            <div
                                onClick={(e) => {
                                    localStorage.clear();
                                    window.location.reload();
                                }}
                            >
                                Logout
                            </div>
                        </div>
                    </>
                );

            default:
                return (
                    <>
                        <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
                            <Scrollbars
                                className="scrollForm"
                                renderTrackHorizontal={() => <div />}
                                renderThumbHorizontal={() => <div />}
                                renderTrackVertical={() => <div />}
                                renderThumbVertical={() => <div />}
                            >
                                {/* Publication Name */}


                                {/* Select Connected App */}
                                {/* <div className="name">Select Connected App</div>
                {app?.app_code ? (
                  <div className="user" onClick={() => setStep("app")}>
                    <img className="dp" src={app?.app_icon} alt="" />
                    <div className="userDetail">
                      <div className="name">{app?.app_name}</div>
                      <div className="email">{app?.app_code}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep("app")}>
                    <input
                      type="text"
                      className="text"
                      placeholder="Click Here To Select An App"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )} */}
                                {/* Select Country */}
                                <div className="name">Which course are you adding this section to?</div>
                                {selectCourse?.name ? (
                                    <div className="countryuser" onClick={() => setStep("selectcourse")}>
                                        <img className="dp" src={selectCourse?.profile_pic} alt="" />
                                        &nbsp;&nbsp;
                                        <div>
                                            <div className="name">{selectCourse?.name}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="inputWrap" style={{ cursor: "pointer" }} onClick={() => setStep("selectcourse")}>
                                        <input
                                            type="text"
                                            className="text"
                                            placeholder="Select Course"
                                            readOnly
                                            style={{ cursor: "pointer" }}
                                        />
                                        <div
                                            className="btnCheck"
                                            style={{
                                                border: "none",
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faCaretDown} />
                                        </div>
                                    </div>
                                )}

                                <div className="name">What is the name of the section?</div>
                                <div className="inputWrap">
                                    <input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        type="text"
                                        className="text"
                                        placeholder="Name..."
                                    />
                                </div>

                                {/* Publication Description */}
                                <div className="name">What is the description for this section?</div>
                                {/* <div className="inputWrap"> */}
                                <textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    type="text"
                                    className="text-textarea"
                                    placeholder="Description..."
                                    rows={5}
                                    cols={40}
                                />
                                {/* </div> */}

                                {/* Publication Webiste */}
                                <div className="name">What is the placement of this section in the course?</div>
                                <div className="inputWrap">
                                    <div>
                                    </div>
                                    <input
                                        value={orderNum}
                                        onChange={(e) => setOrderNum(e.target.value)}
                                        type="number"
                                        className="text"
                                        placeholder="Enter order number..."
                                    />
                                </div>

                                <div>

                                    &nbsp;
                                    <div
                                        className="NextStepBtn"
                                        onClick={() => validate()}
                                        style={{
                                            opacity:
                                                name &&
                                                    description &&
                                                    orderNum &&
                                                    selectCourse
                                                    ? 1
                                                    : 0.3,
                                        }}
                                    >
                                        Add Section
                                    </div>
                                    &nbsp;
                                </div>
                                <div className="space"></div>
                            </Scrollbars>
                        </div>



                        {/* <div className="ftBtns">
              <div
                className="newField"
                style={{ fontWeight: 700 }}
                onClick={(e) => {
                  setStep("");
                  setMainMenu("");
                }}
              >
                Go Back
              </div>
              <div
                className="btnSubmit"
                onClick={() => validate()}
                style={{ fontWeight: 700, opacity: visibleSubmit ? 1 : 0.3 }}
              >
                Submit App
              </div>
            </div> */}
                    </>
                );
        }
    }

    const validate = () => {
        if (
            name &&
            description &&
            orderNum &&
            selectCourse
        ) {
            setVisibleSubmit(true);
            createNewSection();
        } else {
            toast.warning("All Fields Are Mandatory");
        }
    };

    const createNewSection = () => {
        setLoading(true);
        axios
            .post(`https://publications.apimachine.com/sections/create`, {
                course_id: selectCourse._id,
                section_name: name,
                section_description: description,
                section_order: orderNum
            })
            .then(({ data }) => {
                setLoading(false);
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    }
                    toast.error(data.message || "API Error");
                } else {
                    setStep("success");
                    setTimeout(() => {
                        setStep("");
                        setMainMenu("");
                        setTabSelected("Sections");
                        setSlider(false);
                        setStep("");
                        setRefetchCourses(!refetchCourses);
                    }, 2000);
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            })
    };

    // const createApp = () => {
    //   setLoading(true);
    //   axios
    //     .post(`https://comms.globalxchange.io/gxb/apps/create`, {
    //       app_name: appName,
    //       app_code: appCode,
    //       profile_start_code: appCode,
    //       parent_group_id: conglomerate.group_id,
    //       GXNativeapp: true,
    //       mobileApp: true,
    //       short_description: shortDesc,
    //       long_description: longDesc,
    //       categories: categories.map((o) => o.category_id),
    //       color_codes: [
    //         {
    //           primarycolourcode: primaryColour,
    //         },
    //       ],
    //       user_label: callUsers,
    //       app_icon: colouredIcon,
    //       white_logo: whiteLogo2,
    //       website: website,
    //       cover_photo: coverPhoto,
    //       operatorid: brand.operator_id,
    //       email: bankerEmail,
    //       token: loginData.idToken,
    //       country: country.name,
    //       data: {
    //         color_logo: colouredLogo,
    //         white_icon: whiteLogo,
    //         website_description: websiteDesc,
    //         website_title: websiteTitle,
    //         currencyname: displayCurrency.coinSymbol,
    //       },
    //     })
    //     .then(({ data }) => {
    //       if (data.status === false) {
    //         toast.success(data.message || "API Error");
    //       } else setStep("success");
    //     })
    //     .catch((error) => {
    //       toast.error(error?.response?.data?.message);
    //     })
    //     .finally(() => setLoading(false));
    // };

    return (
        <>
            {loading ? (
                // <div
                //   style={{
                //     display: "flex",
                //     flexDirection: "column",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     height: "70vh",
                //   }}
                // >
                //   <div className="loader"></div>
                //   <div style={{ padding: "20px", fontWeight: 600, color: "#010101" }}>
                //     Creating New Publication ...
                //   </div>
                // </div>
                <div
                    style={{
                        height: window.innerHeight,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                            marginBottom: "40px",
                            fontWeight: 600,
                            color: "#010101",
                            fontSize: "20px",
                        }}
                    >
                        Creating {name} ...
                    </div>
                </div>
            ) : (
                <>
                {getContent()}
                <ToastContainer />
                </>
            )}
        </>
    );
};

export default NewCourseSection;
