import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import * as jose from "jose";
import cloudUploadIcon from "../../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../../static/images/loading.gif";

import bonds from "../../../static/images/sidebarIcons/bonds.svg";
import plus from "../../../static/images/icons/plus.svg";

import "./newBondTier.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";
import clearInput from "../../../static/images/icons/refresh.svg";

import { GlobalContex } from "../../../globalContex";
import CountryList from "./CountryList";
import PartnerInstituteList from "./PartnerInstituteList";

// import { useBankerDetails } from "../../../queryHooks";
// import jwt from "jsonwebtoken";
// import { sign } from "react-native-pure-jwt";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

const NewBankerProfile = ({ step, setStep, loading, setLoading,setMainMenu }) => {
  const navigate = useNavigate();

  const {
    loginData,
    bankerEmail,
    allBankers,
    tabs,
    setTabs,
    selectedTab,
    setSelectedTab,
    setGlobalMenuAdd,
    FormatCurrency,
    FormatNumber,
    allCoins,
  } = useContext(GlobalContex);

  // Form Variables

  const [bankerTag, setBankerTag] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [country, setCountry] = useState(null);
  const [desc, setDesc] = useState("");
  const [colourCode, setColourCode] = useState("");

  const [colouredIcon, setColouredIcon] = useState("");
  const [colouredIconPng, setColouredIconPng] = useState("");
  const [whiteIcon, setWhiteIcon] = useState("");
  const [whiteIconPng, setWhiteIconPng] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");

  const [colouredIconLoading, setColouredIconLoading] = useState("");
  const [colouredIconPngLoading, setColouredIconPngLoading] = useState("");
  const [whiteIconLoading, setWhiteIconLoading] = useState("");
  const [whiteIconPngLoading, setWhiteIconPngLoading] = useState("");
  const [coverPhotoLoading, setCoverPhotoLoading] = useState("");

  const [partnerInstitution, setPartnerInstitution] = useState([]);
  const [address, setAddress] = useState("");
  const [autoDeposit, setAutoDeposit] = useState(false);

  useEffect(() => {
    localStorage.setItem("step", step);
  }, [step]);

  useEffect(() => {
    console.log(loading, "kjqwbdkwed");
  }, [loading]);

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };

  const uploadImage = async (e, setImage, setLoading1) => {
    setLoading1(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";

    const jwts = await signJwt(fileName, emailDev, secret);
    console.log(jwts, "lkjkswedcf");
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading1(false);
  };

  const handleReplaceTab = () => {
    const item = {
      menuName: "Bonds",
      menuIcon: bonds,
      enabled: true,
    };
    if (
      tabs.findIndex((o) => o.menuName === item.menuName) < 0 ||
      tabs.length < 1
    ) {
      const index = tabs.findIndex((o) => o.menuName === selectedTab.menuName);
      console.log(
        tabs.findIndex((o) => o.menuName === selectedTab.menuName),
        selectedTab,
        "jhwgjwhe"
      );
      tabs.splice(index, 1, item);
      setSelectedTab(tabs.length < 1 ? tabs[index] : item);
    } else {
      setSelectedTab(item);
    }
  };

  function getContent() {
    switch (step) {
      case "Success":
        setTimeout(() => {
          // navigate("/ventures/Brands");
          setGlobalMenuAdd(false);
          //   handleReplaceTab();
        }, 2000);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm" style={{ textAlign: "center" }}>
                You Have Successfully Created a New Banker Profile.
              </div>
            </div>
          </div>
        );

      case "Failed":
        setTimeout(() => {
          // navigate("/ventures/Brands");
          setGlobalMenuAdd(false);
          //   handleReplaceTab();
        }, 2000);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm" style={{ textAlign: "center" }}>
                Banker Profile Creation Failed
              </div>
            </div>
          </div>
        );

      case "Select Country":
        return (
          <div className="newConglomerate" style={{ paddingTop: 0 }}>
            <CountryList setCountry={setCountry} onClose={() => setStep("")} />
          </div>
        );

      case "Add Partner Institution":
        return (
          <div className="newConglomerate">
            <PartnerInstituteList
              partnerInstitution={partnerInstitution}
              setPartnerInstitution={setPartnerInstitution}
              onClose={() => setStep("")}
            />
          </div>
        );

      default:
        return (
          <>
            <div className="newConglomerate">
              <Scrollbars
                className="scrollForm"
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
              >
                {/* Create Banker Tag  */}
                <div className="name">Create Banker Tag</div>
                <div className="inputWrap">
                  <input
                    value={bankerTag}
                    onChange={(e) => setBankerTag(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Banker Tag..."
                  />
                </div>
                {/* Create Display Name */}
                <div className="name">Create Display Name</div>
                <div className="inputWrap">
                  <input
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Display Name..."
                  />
                </div>
                {/* Select Country */}
                <div className="name">Select Country</div>
                {country?.name ? (
                  <div
                    className="user"
                    onClick={() => setStep("Select Country")}
                  >
                    <img className="dp" src={country?.image} alt="" />
                    <div className="userDetail">
                      <div className="name">{country?.name}</div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => setStep("Select Country")}
                  >
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}
                {/* Enter Description  */}
                <div className="name">Enter Description</div>
                <div className="inputWrap">
                  <input
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Description..."
                  />
                </div>
                {/* Enter Colour Code */}
                <div className="name">Enter Colour Code</div>
                <div className="inputWrap">
                  <input
                    value={colourCode}
                    onChange={(e) => setColourCode(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Colour Code..."
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      background: colourCode ? "#" + colourCode : "#ffffff",
                      width: "68px",
                    }}
                  >
                    &nbsp;
                  </div>
                </div>
                {/* Upload Logos */}
                <div className="name">Upload Logos</div>
                <div className="filesUpload">
                  <label className="fileInp icon" style={{}}>
                    <img
                      className={`${Boolean(colouredIcon)}`}
                      src={
                        colouredIconLoading
                          ? loadingGif
                          : colouredIcon || cloudUploadIcon
                      }
                      alt=""
                      style={{ width: "40px" }}
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setColouredIcon, setColouredIconLoading);
                      }}
                      accept="image/*"
                    />
                    <div
                      className="text"
                      style={{ fontWeight: "700", bottom: "-40px" }}
                    >
                      Coloured Icon
                    </div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(colouredIconPng)}`}
                      src={
                        colouredIconPngLoading
                          ? loadingGif
                          : colouredIconPng || cloudUploadIcon
                      }
                      alt=""
                      style={{ width: "40px" }}
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(
                          e,
                          setColouredIconPng,
                          setColouredIconPngLoading
                        );
                      }}
                      accept="image/*"
                    />
                    <div
                      className="text"
                      style={{ fontWeight: "700", bottom: "-40px" }}
                    >
                      Coloured Icon (PNG)
                    </div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(whiteIcon)}`}
                      src={
                        whiteIconLoading
                          ? loadingGif
                          : whiteIcon || cloudUploadIcon
                      }
                      alt=""
                      style={{ width: "40px" }}
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setWhiteIcon, setWhiteIconLoading);
                      }}
                      accept="image/*"
                    />
                    <div
                      className="text"
                      style={{ fontWeight: "700", bottom: "-40px" }}
                    >
                      White Icon
                    </div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                </div>
                <div className="filesUpload">
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(whiteIconPng)}`}
                      src={
                        whiteIconPngLoading
                          ? loadingGif
                          : whiteIconPng || cloudUploadIcon
                      }
                      alt=""
                      style={{ width: "40px" }}
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setWhiteIconPng, setWhiteIconPngLoading);
                      }}
                      accept="image/*"
                    />
                    <div
                      className="text"
                      style={{ fontWeight: "700", bottom: "-40px" }}
                    >
                      White Icon (PNG)
                    </div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(coverPhoto)}`}
                      src={
                        coverPhotoLoading
                          ? loadingGif
                          : coverPhoto || cloudUploadIcon
                      }
                      alt=""
                      style={{ width: "40px" }}
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                      }}
                      accept="image/*"
                    />
                    <div
                      className="text"
                      style={{ fontWeight: "700", bottom: "-40px" }}
                    >
                      Cover Photo
                    </div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                  <label
                    className="fileInp icon"
                    style={{ visibility: "hidden" }}
                  ></label>
                </div>
                {/* Add Partner Institution */}
                <div
                  className="name"
                  style={{ paddingTop: "20px", paddingBottom: "10px" }}
                >
                  Add Partner Institution
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {partnerInstitution.map((item, index) => {
                    return (
                      <div
                        onClick={(e) => {
                          setPartnerInstitution(
                            partnerInstitution.filter((o) => o._id !== item._id)
                          );
                        }}
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            border: "0.5px solid #E7E7E7",
                            padding: "30px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <img
                            src={item?.profile_image}
                            alt=""
                            style={{ width: "60px", height: "60px" }}
                          />
                        </div>
                        <div
                          style={{
                            wordWrap: "break-word",
                            width: "100px",
                            textAlign: "center",
                          }}
                        >
                          {item?.short_name}
                        </div>
                      </div>
                    );
                  })}
                  <div
                    onClick={(e) => setStep("Add Partner Institution")}
                    style={{
                      width: "100px",
                      height: "100px",
                      border: "0.5px solid #E7E7E7",
                      padding: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <img src={plus} alt="" />
                  </div>
                </div>
                {/* Enter Your Address  */}
                <div className="name" style={{ paddingTop: "40px" }}>
                  Enter Your Address
                </div>
                <div className="inputWrap">
                  <input
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Address..."
                  />
                </div>

                {/* Turn On Auto Deposit  */}
                <div className="name" style={{ paddingTop: "0px" }}>
                  Turn On Auto Deposit
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    margin: "20px 0 40px 0",
                  }}
                >
                  <div
                    className={autoDeposit ? "btnSelected" : "btn3"}
                    onClick={(e) => setAutoDeposit(true)}
                  >
                    Yes
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <div
                    className={!autoDeposit ? "btnSelected" : "btn3"}
                    onClick={(e) => setAutoDeposit(false)}
                  >
                    No
                  </div>
                </div>
                <div className="space"></div>
              </Scrollbars>
            </div>

            <div className="footerBtns">
              <div
                className="newField"
                style={{ fontWeight: 700 }}

                onClick={(e) => {
                  setStep("");
                  setMainMenu("");
                }}              >
                Go Back
              </div>
              <div
                className={
                  bankerTag &&
                  colourCode &&
                  address &&
                  coverPhoto &&
                  displayName &&
                  desc &&
                  partnerInstitution &&
                  country &&
                  colouredIcon &&
                  colouredIconPng &&
                  whiteIcon &&
                  whiteIconPng
                    ? "btnSelected"
                    : "btnSubmit"
                }
                style={{
                  fontSize: "20px",
                  opacity:
                    bankerTag &&
                    colourCode &&
                    address &&
                    coverPhoto &&
                    displayName &&
                    desc &&
                    partnerInstitution &&
                    country &&
                    colouredIcon &&
                    colouredIconPng &&
                    whiteIcon &&
                    whiteIconPng
                      ? 1
                      : 0.5,
                }}
                onClick={() => validate()}
              >
                Submit App
              </div>
            </div>
          </>
        );
    }
  }

  const validate = () => {
    if (
      bankerTag &&
      colourCode &&
      address &&
      coverPhoto &&
      displayName &&
      desc &&
      partnerInstitution &&
      country &&
      colouredIcon &&
      colouredIconPng &&
      whiteIcon &&
      whiteIconPng
    ) {
      setLoading(true);
      createBankerProfile();
    } else {
      toast.warning("All Fields Are Mandatory");
    }
  };

  const createBankerProfile = () => {
    setLoading(true);
    axios
      .post(
        `https://teller2.apimachine.com/lxUser/register/banker`,
        {
          bankerTag: bankerTag,
          colorCode: colourCode,
          address: address,
          coverPicURL: coverPhoto,
          displayName: displayName,
          description: desc,
          partneringInstitutions: partnerInstitution.map((o) => o._id),
          country: country.name,
          profilePicURL: colouredIcon,
          profilePicPNG: colouredIconPng,
          profilePicWhite: whiteIcon,
          profilePicWhitePNG: whiteIconPng,
          autoDeposit: autoDeposit,
        },
        {
          headers: {
            email: bankerEmail,
            token: loginData?.idToken,
          },
        }
      )
      .then(({ data }) => {
        if (data.status === false) {
          setStep("Failed");
          toast.success(data.message || "API Error");
        } else setStep("Success");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        // setBondError(error?.response?.data?.message);
        setStep("Failed");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {/* {getContent()} */}
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <div className="loader"></div>
          <div style={{ padding: "20px", fontWeight: 600, color: "#010101" }}>
            Creating New banker Profile ...
          </div>
        </div>
      ) : (
        getContent()
      )}
    </>
  );
};

export default NewBankerProfile;
