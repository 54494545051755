import React, { useContext, useEffect, useState } from "react";
import "../AddNewCategory/category.scss";
import { GlobalContex } from "../../globalContex";
import NavBar from "../../globalComponents/NavBar";
import axios from "axios";
import cloudUploadIcon from "../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../static/images/loading.gif";
import CloseIcon from "../../static/images/close.svg";
import { ToastContainer, toast, useToast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const AddNewBusiness = () => {
  const tabs = ["Add Business"];
  const [updatedData, setUpdatedData] = useState("");
  const [whiteIcon, setWhiteIcon] = useState("");
  const [whiteIconLoading, setWhiteIconLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [coverPhoto, setCoverPhoto] = useState("");
  const [coverLoading, setCoverLoading] = useState("");
  const [whiteCoverPhoto, setWhiteCoverPhoto] = useState("");
  const [whiteCoverLoading, setWhiteCoverLoading] = useState("");
  const [additionalLoading, setAdditionalLoading] = useState(false);
  const [additionalPhoto, setAdditionalPhoto] = useState("");
  const [metaArr, setMetaArr] = useState([]);
  const [metaTag, setMetaTag] = useState("");
  const [altTagsArr, setAltTagsArr] = useState([]);
  const [altTags, setAltTags] = useState("");
  const [imageArr, setImageArr] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [categoryNumber, setCategoryNumber] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [webDescription, setWebDescription] = useState("");
  const [status, setStatus] = useState("online");
  const [colourCode, setColourCode] = useState("");
  const [secondColourCode, setSecondColourCode] = useState("");
  const [textColourCode, setTextColourCode] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const [username, setUserName] = useState("");
  const [fetchUsers, setFetchUsers] = useState([]);
  const [websiteLink, setWebsiteLink] = useState("");
  const [selectUsername, setSelectUsername] = useState("");
  const [connectedApp, setConnectedApp] = useState("");
  const [acceptConditions, setAcceptConditions] = useState(false)
  const [businessType, setBusinessType] = useState("brand")
  const navigate = useNavigate();

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [street, setStreet] = useState("");

  const {
    tabSelected,
    setTabSelected,
    businessTrigger,
    bankerEmail,
    setBusinessTrigger,
    setAddNewField,
    selectedBusiness,
  } = useContext(GlobalContex);

  useEffect(() => {
    setTabSelected("Add Business");
    // axios.get(
    //     `https://frissly.apimachine.com/api/business`
    // ).then(({ data }) => {
    //     if (data.status) {
    //         setFetchUsers(data.data);
    //         setUserName(data?.data[0]?.businessusername)
    //     }
    // });
  }, []);

  useEffect(() => {
    if (businessTrigger) {
      //   console.log(body, "fwetwe");
      if (name) {
        setApiLoading(true);
        const media = imageArr.map((url) => ({ link: url }));
        let body = {
          email: bankerEmail,
          name: name,
          icon: updatedData,
          logo: coverPhoto,
          website: websiteLink,
          description: description,
          colourCode: colourCode,
          appCode: "godating",
          business_type: businessType,
          businessusername: selectUsername,
          colourCode2: secondColourCode,
          textColourCode: textColourCode,
          websiteTitle: webTitle,
          websiteDescription: webDescription,
          whiteIcon: whiteIcon,
          whiteLogo: whiteCoverPhoto,
          media: media,
          address: {
            Street: street,
            Contact: phoneNo,
            Landmark: landmark,
            City: city,
            State: state,
            Pincode: pincode,
            Country: "India",
          },
        };
        console.log(body, "fwetwe");
        if (body.name || body.businessusername) {
          axios
            .post("https://frissly.apimachine.com/api/business/create", body)
            .then(({ data }) => {
              if (data.status) {
                toast.success("Business Added Successfully");
                setTimeout(() => {
                  setAddNewField(false);
                  navigate("/Admin");
                }, 1500);
              } else {
                toast.error(data?.message || "Something went wrong");
              }
            })
            .catch((err) => {
              toast.error("API Error");
            });
        }
        setApiLoading(false);
      }
    }
    setBusinessTrigger(false);
  }, [businessTrigger]);

  function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  const uploadImage = async (e, setUpdatedData, setLoading, theText) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);

    let { data } = await axios.post(
      `https://publications.apimachine.com/article/upload`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setUpdatedData(data[0].urlName);
    setLoading(false);
    console.log(theText);
    if (theText === "additionalImg" && data[0].urlName) {
      setImageArr([...imageArr, data[0].urlName]);
    }
  };

  const addMetaTag = (metaTag) => {
    if (metaTag === "") return;
    setMetaArr([...metaArr, metaTag]);
    console.log(metaArr, "metaArr");
    setMetaTag("");
  };

  const removeTag = (item) => {
    setMetaArr(metaArr.filter((tag) => tag !== item));
  };

  const removeImages = (item) => {
    setImageArr(imageArr.filter((tag) => tag !== item));
  };

  const addAltTag = (tag) => {
    if (tag === "") return;
    setAltTagsArr([...altTagsArr, tag]);
    setAltTags("");
  };

  const removeAltTag = (item) => {
    setAltTagsArr(altTagsArr.filter((keyword) => keyword !== item));
  };

  return (
    <>
      <NavBar
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={setTabSelected}
        enabledFilters={[true, true, true, false, false, true]}
        apiLoading={apiLoading}
        setApiLoading={setApiLoading}
      />
      <div className="formDisplayData">
        <div>
          <div className="basicForm">
            <p className="categoryTitle">Basic Details</p>
            <p className="categoryName">Name</p>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="inputStyle"
              placeholder="Example: Cookies"
            />
            {/* <p className="categoryName topmargin">Select Business User</p> */}
            {/* <div className="select-wrapper">
                            <select name="Online" className="selectStyle" value={username} onChange={(e) => setUserName(e.target.value)}>
                                {fetchUsers.map((e) => {
                                    return <>
                                        <option value={e.businessusername}>{e?.businessusername}</option>
                                    </>
                                })}
                            </select>
                        </div> */}
            <p className="categoryName topmargin">Description</p>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="6"
              className="inputStyle"
              placeholder="Example: Cookies"
            ></textarea>
            <p className="categoryName topmargin">Website Link</p>
            <input
              type="text"
              value={websiteLink}
              onChange={(e) => setWebsiteLink(e.target.value)}
              className="inputStyle"
              placeholder="Link"
            />
            <p className="categoryName topmargin">Business username</p>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                value={selectUsername}
                onChange={(e) => setSelectUsername(e.target.value)}
                className="inputStyle"
                placeholder="username"
              />
              <button className="availabilitybtn">
                Check&nbsp;Availability
              </button>
            </div>
            <p className="categoryName topmargin">What type of business are you?</p>
            <div style={{ display: "flex" }}>
              <div onClick={() => setBusinessType("brand")} className={"groupBtn " + (businessType === "brand" ? "active" : "")}>Brand</div>
              &nbsp;&nbsp;
              <div onClick={() => setBusinessType("venue")} className={"groupBtn " + (businessType === "venue" ? "active" : "")}>Venue</div>
              &nbsp;&nbsp;
              <div onClick={() => setBusinessType("other")} className={"groupBtn " + (businessType === "other" ? "active" : "")}>Other</div>
            </div>
          </div>
          <br />
          <div className="basicForm">
            <p className="categoryTitle">SEO Details</p>
            <p className="categoryName">Website title</p>
            <input
              type="text"
              value={webTitle}
              onChange={(e) => setWebTitle(e.target.value)}
              className="inputStyle"
              placeholder="Title"
            />
            <p className="categoryName topmargin">Website Description</p>
            <textarea
              value={webDescription}
              onChange={(e) => setWebDescription(e.target.value)}
              rows="6"
              className="inputStyle"
              placeholder="Description"
            ></textarea>
            {/* <p className="categoryName topmargin">Metatags</p>
                        {metaArr.map((item, index) => {
                            return <><div style={{ display: "flex" }}>
                                <input type="text" value={item} className="inputStyle" placeholder="Description" disabled style={{ opacity: 0.5 }} />
                                &nbsp;&nbsp;
                                <button className="removeBtn" onClick={() => removeTag(item)}>Remove</button>
                            </div>
                                <br />
                            </>
                        })}
                        &nbsp;
                        <div style={{ display: "flex" }}>
                            <input type="text" value={metaTag}
                                onChange={(e) => setMetaTag(e.target.value)} className="inputStyle" placeholder="Example: Food" />
                            &nbsp;&nbsp;
                            <button className="addBtn" onClick={() => addMetaTag(metaTag)} >Save</button>
                        </div>
                        <p className="categoryName topmargin">AltTags</p>
                        {altTagsArr.map((item, index) => {
                            return (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <input type="text" value={item} className="inputStyle" placeholder="Example: Food" disabled style={{ opacity: 0.5 }} />
                                        &nbsp;&nbsp;
                                        <button className="removeBtn" onClick={() => removeAltTag(item)} >Remove</button>
                                    </div>
                                    <br />
                                </>
                            )
                        })}
                        &nbsp;
                        <div style={{ display: "flex" }}>
                            <input type="text" value={altTags}
                                onChange={(e) => setAltTags(e.target.value)} className="inputStyle" placeholder="Example: Food" />
                            &nbsp;&nbsp;
                            <button className="addBtn" onClick={() => addAltTag(altTags)}>Save</button>
                        </div> */}
          </div>
          <br />
          <div className="basicForm">
            <p className="categoryTitle">Address</p>
            <p className="categoryName">Street</p>
            <input
              type="text"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              className="inputStyle"
            //   placeholder="Street"
            />

            <p className="categoryName" style={{ paddingTop: "20px" }}>
              Landmark
            </p>
            <input
              type="text"
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              className="inputStyle"
            //   placeholder="Title"
            />

            <p className="categoryName" style={{ paddingTop: "20px" }}>
              City
            </p>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="inputStyle"
            //   placeholder="Title"
            />
            <p className="categoryName" style={{ paddingTop: "20px" }}>
              State
            </p>
            <input
              type="text"
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="inputStyle"
            //   placeholder="Title"
            />
            <p className="categoryName" style={{ paddingTop: "20px" }}>
              Pincode
            </p>
            <input
              type="text"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              className="inputStyle"
            //   placeholder="Title"
            />
            <p className="categoryName" style={{ paddingTop: "20px" }}>
              Phone number
            </p>
            <input
              type="text"
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
              className="inputStyle"
            //   placeholder="Title"
            />

          </div>
          <br />
          <div className="basicForm">
            <p className="terms-title">Terms & Conditions</p>
            <div style={{ display: "flex" }}>
              <div>
                <input type="checkbox" className="checkbox-terms" value={acceptConditions} />
              </div>
              <div className="terms-check">I agree with all the terms & conditions outlined in vendor agreement. Find link <a className="terms-link" href="https://montagestr.s3.amazonaws.com/Go+Dating-Venue-partners+Agreement.docx.pdf" target="blank">here</a>.</div>
            </div>
          </div>

          {/* <br /> */}
          {/* <div className="basicForm">
                        <p className="categoryTitle">Status</p>
                        <div className="select-wrapper">
                            <select name="Online" className="selectStyle" value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="online">Online</option>
                                <option value="offline">Offline</option>
                            </select>
                        </div>
                    </div> */}
        </div>
        <div>
          <div className="basicForm">
            <p className="categoryTitle">Cosmetic Details</p>
            <div style={{ display: "flex" }}>
              <div>
                <p className="categoryName">Icon</p>
                <div
                  className="filesUpload"
                  style={{ height: "120px", padding: 0, width: "100px" }}
                >
                  <label
                    className="fileInp icon"
                    style={{
                      flex: "0 0 100%",
                      maxWidth: "103px",
                      height: "100px",
                      borderRadius: "10px",
                      background: "white",
                    }}
                  >
                    <img
                      style={{
                        width: updatedData ? "100px" : "46px",
                        height: updatedData ? "100px" : "46px",
                      }}
                      className={`${Boolean(updatedData)}`}
                      src={
                        imgLoading ? loadingGif : updatedData || cloudUploadIcon
                      }
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setUpdatedData, setImgLoading);
                      }}
                      accept="image/*"
                    />

                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                </div>
              </div>
              <div style={{ marginLeft: "30px" }}>
                <p className="categoryName">White Icon</p>
                <div
                  className="filesUpload"
                  style={{ height: "120px", padding: 0, width: "100px" }}
                >
                  <label
                    className="fileInp icon"
                    style={{
                      flex: "0 0 100%",
                      maxWidth: "103px",
                      height: "100px",
                      borderRadius: "10px",
                      background: "white",
                    }}
                  >
                    <img
                      style={{
                        width: whiteIcon ? "100px" : "46px",
                        height: whiteIcon ? "100px" : "46px",
                      }}
                      className={`${Boolean(whiteIcon)}`}
                      src={
                        whiteIconLoading
                          ? loadingGif
                          : whiteIcon || cloudUploadIcon
                      }
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setWhiteIcon, setWhiteIconLoading);
                      }}
                      accept="image/*"
                    />

                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <p className="categoryName topmargin">Logo</p>
            <div
              className="filesUpload"
              style={{ height: "120px", padding: 0 }}
            >
              <label
                className="fileInp icon"
                style={{
                  flex: "0 0 100%",
                  maxWidth: "100%",
                  height: "170px",
                  borderRadius: "10px",
                  background: "white",
                  position: "relative",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    width: coverPhoto ? "100%" : "46px",
                    height: coverPhoto ? "170px" : "46px",
                    objectFit: coverPhoto ? "cover" : "",
                    border: coverPhoto ? "1px solid #E7E7E7" : "",
                    borderRadius: "10px",
                    marginTop: coverPhoto ? "22px" : "",
                  }}
                  className={`${Boolean(coverPhoto)}`}
                  src={
                    coverLoading ? loadingGif : coverPhoto || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setCoverPhoto, setCoverLoading);
                  }}
                  accept="image/*"
                />

                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
                <br />
                <p
                  className="imgText"
                  style={{ display: coverPhoto ? "none" : "" }}
                >
                  <span className="highlightText">Click to Upload</span> or drag
                  and drop
                </p>
              </label>
            </div>
            <br />
            <p className="categoryName topmargin">White Logo</p>
            <div
              className="filesUpload"
              style={{ height: "120px", padding: 0 }}
            >
              <label
                className="fileInp icon"
                style={{
                  flex: "0 0 100%",
                  maxWidth: "100%",
                  height: "170px",
                  borderRadius: "10px",
                  background: "white",
                  position: "relative",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    width: whiteCoverPhoto ? "100%" : "46px",
                    height: whiteCoverPhoto ? "170px" : "46px",
                    objectFit: whiteCoverPhoto ? "cover" : "",
                    border: whiteCoverPhoto ? "1px solid #E7E7E7" : "",
                    borderRadius: "10px",
                    marginTop: whiteCoverPhoto ? "22px" : "",
                  }}
                  className={`${Boolean(whiteCoverPhoto)}`}
                  src={
                    whiteCoverLoading
                      ? loadingGif
                      : whiteCoverPhoto || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setWhiteCoverPhoto, setWhiteCoverLoading);
                  }}
                  accept="image/*"
                />

                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
                <br />
                <p
                  className="imgText"
                  style={{ display: coverPhoto ? "none" : "" }}
                >
                  <span className="highlightText">Click to Upload</span> or drag
                  and drop
                </p>
              </label>
            </div>
            <br />
            <p className="categoryName topmargin">Colour Code</p>
            <div style={{ position: "relative", display: "flex" }}>
              <input
                value={colourCode}
                onChange={(e) => setColourCode(e.target.value)}
                type="text"
                className="inputStyle"
                placeholder="Example: 00000"
              />
              <div
                className="colourBox"
                style={{ background: colourCode }}
              ></div>
            </div>
            <br />
            <p className="categoryName topmargin">Second Colour Code</p>
            <div style={{ position: "relative", display: "flex" }}>
              <input
                value={secondColourCode}
                onChange={(e) => setSecondColourCode(e.target.value)}
                type="text"
                className="inputStyle"
                placeholder="Example: 00000"
              />
              <div
                className="colourBox"
                style={{ background: secondColourCode }}
              ></div>
            </div>
            <br />
            <p className="categoryName topmargin">Text Colour Code</p>
            <div style={{ position: "relative", display: "flex" }}>
              <input
                value={textColourCode}
                onChange={(e) => setTextColourCode(e.target.value)}
                type="text"
                className="inputStyle"
                placeholder="Example: 00000"
              />
              <div
                className="colourBox"
                style={{ background: textColourCode }}
              ></div>
            </div>
          </div>
          <br />
          <div className="basicForm">
            <p className="categoryTitle">Additional Media</p>
            <p className="categoryName topmargin">Accept’s Images & Videos</p>
            {imageArr.map((e, index) => {
              return (
                <>
                  <div className="fitImage">
                    <img
                      src={CloseIcon}
                      alt="close"
                      className="theCloseIcon"
                      onClick={() => removeImages(e)}
                    />
                    <img src={e} alt="images" className="additionalImages" />
                  </div>
                  <br />
                </>
              );
            })}
            <div
              className="filesUpload"
              style={{ height: "120px", padding: 0 }}
            >
              <label
                className="fileInp icon"
                style={{
                  flex: "0 0 100%",
                  maxWidth: "100%",
                  height: "170px",
                  borderRadius: "10px",
                  background: "white",
                  position: "relative",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{ width: "46px", height: "46px" }}
                  className={`${Boolean(additionalPhoto)}`}
                  src={additionalLoading ? loadingGif : cloudUploadIcon}
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(
                      e,
                      setAdditionalPhoto,
                      setAdditionalLoading,
                      "additionalImg"
                    );
                  }}
                  accept="image/*"
                />

                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
                <br />
                <p className="imgText">
                  <span className="highlightText">Click to Upload</span> or drag
                  and drop
                </p>
              </label>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddNewBusiness;
