import React, { useContext, useState } from "react";
// import "../../../../../static/scss/subdrawer.scss";
// import "./subdrawer.module.scss"
// import "./subdrawer.scss"
import close from "../../../../../static/images/icons/close.svg";
import LoadingAnimation from "../../../../../globalComponents/LoadingAnimation";
import PurpleClose from "../../../../../static/images/purpleClose.svg"
import lock from "../../../../../static/images/icons/lock.svg";
import Axios from "axios";
import * as jose from "jose";
import loadingGif from "../../../../../static/images/loading.gif";
import cloudUploadIcon from "../../../../../static/images/clipIcons/cloudUpload.svg";
import uploadGroupIcon from "../../../../../static/images/uploadGroupIcon.svg"
import { ToastContainer, toast } from "react-toastify";
import { GlobalContex } from "../../../../../globalContex";
import { useEffect } from "react";

function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.


const ColonyDrawer = () => {
    const [step, setStep] = useState("default")
    const [name, setName] = useState("");
    const [username, setUsername] = useState("")
    const [bio, setBio] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [twitter, setTwitter] = useState("");
    const [country, setCountry] = useState("");
    const [colouredIcon, setColouredIcon] = useState("");
    const [colouredIconLoading, setColouredIconLoading] = useState("");
    const [path, setPath] = useState([]);
    const [successMsg, setSuccessMsg] = useState("")
    const [loadingtext, setloadingtext] = useState("")
    const [allCategories, setAllCategories] = useState([])
    const [cateNum, setCateNum] = useState(0)
    const [description, setDescription] = useState("")
    const [gstCode, setGSTcode] = useState("")
    const [shortForm, setShortForm] = useState("")
    const [allStates, setAllStates] = useState([])
    const [allCities, setAllCities] = useState([])
    const [allAreas, setAllAreas] = useState([])
    const [allPincodes, setAllPincodes] = useState([])


    const { bankerEmail, setTabSelected,
        refetchProduct, setRefechProduct, selectedBusiness, colonyDrawerOpen, SetColonyDrawerOpen,
        selectedColony, setSelectedColony } = useContext(GlobalContex);

    useEffect(() => {
        setStep("Action");
        setPath(["Action"]);
    }, [colonyDrawerOpen]);

    // useEffect(() => {
    //   console.log(ActionBtn, "ActionBtn")
    //   if (ActionBtn === "Actions") {
    //     setStep("Actions")
    //   } else{
    //     setStep("default")
    //   }
    // }, [step, ActionBtn])

    useEffect(() => {
        setStep(path[path.length - 1]);
    }, [path]);

    const signJwt = async (fileName, emailDev, secret) => {
        try {
            const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
                .setProtectedHeader({ alg: "HS512" })
                .setIssuer("gxjwtenchs512")
                .setExpirationTime("10m")
                .sign(new TextEncoder().encode(secret));
            return jwts;
        } catch (error) {
            console.log(error, "kjbedkjwebdw");
        }
    };

    const handleBreadcrumbClick = (clickedStep) => {
        const foundStepIndex = path.findIndex((o) => o === clickedStep);
        const tempPath = path;
        tempPath.splice(foundStepIndex + 1, tempPath.length);
        setPath([...tempPath]);
        console.log(path, "lwndwed");
    };

    // const uploadImage = async (e, setImage, setLoading) => {
    //   setLoading(true);
    //   const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
    //     e.target.files[0].name.lastIndexOf(".")
    //   )}`;
    //   const formData = new FormData();
    //   const file = renameFile(e.target.files[0], fileName);
    //   formData.append("files", file);
    //   const path_inside_brain = "root/";

    //   const jwts = await signJwt(fileName, emailDev, secret);
    //   console.log(jwts, "lkjkswedcf");
    //   let { data } = await Axios.post(
    //     `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
    //     formData,
    //     {
    //       headers: {
    //         "Access-Control-Allow-Origin": "*",
    //       },
    //     }
    //   );
    //   setImage(data.payload.url);
    //   setLoading(false);
    // };

    const uploadImage = async (e, setImage, setLoading) => {
        setLoading(true);
        const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
            e.target.files[0].name.lastIndexOf('.')
        )}`;
        const formData = new FormData();
        const file = renameFile(e.target.files[0], fileName);
        formData.append('files', file);
        const path_inside_brain = 'root/';

        const jwts = await signJwt(fileName, emailDev, secret);
        // console.log(jwts, 'lkjkswedcf');
        let { data } = await Axios.post(
            `https://insurance.apimachine.com/insurance/general/upload`,
            formData,
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }
        );
        console.log(data[0].urlName, 'drive uploaded img');
        setImage(data[0].urlName);
        setLoading(false);
    };






    const deleteColony = () => {
        setStep("loading")
        setloadingtext(`Deleting ${selectedColony?.name}...`)
        Axios.delete(`https://frissly.apimachine.com/api/business/logistics/delete/colony/${selectedColony?._id}`)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully deleted ${selectedColony?.name}. You will now be redirected to your updated list.`)
                    setTimeout(() => {
                        setTabSelected("Colony")
                        setStep("default");
                        setSelectedColony(false);
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }
    const updateColony = (item, field) => {
        let body
        if (name) {
            body = {
                name: name
            }
        }
        if (field === "state") {
            body = {
                stateId: item?._id
            }
        }
        if (field === "city") {
            body = {
                stateId: selectedColony?.stateId,
                cityId: item?._id
            }
        }
        if (field === "area") {
            body = {
                stateId: selectedColony?.stateId,
                cityId: selectedColony?.cityId,
                areaId: item?._id
            }
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating State`)
        Axios.put(`https://frissly.apimachine.com/api/business/logistics/update/colony/${selectedColony?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully updated data for ${selectedColony?.name}. You will now be redirected to your updated list.`)
                    setTimeout(() => {
                        setTabSelected("Colony")
                        setStep("default");
                        setSelectedColony(false);
                        SetColonyDrawerOpen(false)
                        setName("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const getAllStates = () => {
        Axios.get(`https://frissly.apimachine.com/api/business/logistics/get/state?businessId=${selectedBusiness?._id}`)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status) {
                    setAllStates(data.data)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const getAllCities = () => {
        Axios.get(`https://frissly.apimachine.com/api/business/logistics/get/city?businessId=${selectedBusiness?._id}`)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status) {
                    setAllCities(data.data)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const getAllAreas = () => {
        Axios.get(`https://frissly.apimachine.com/api/business/logistics/get/area?businessId=${selectedBusiness?._id}`)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status) {
                    setAllAreas(data.data)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const getAllPincodes = () => {
        Axios.get(`https://frissly.apimachine.com/api/business/logistics/get/pincode?businessId=${selectedBusiness?._id}`)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status) {
                    setAllPincodes(data.data)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const fullHeightDrawer = (message) => {
        if (message) {
            return (
                <div
                    style={{
                        height: window.innerHeight - 150,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                            marginBottom: "20px",
                        }}
                    >
                        {message}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        height: window.innerHeight - 123,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                        }}
                    >
                        Updating Icon...
                    </div>
                </div>
            );
        }
    };


    const conditionalSteps = () => {
        switch (step) {
            case "loading":
                return fullHeightDrawer(loadingtext);
            case "Token Expired":
                return (
                    <>
                        <div className="sidebarTitle">Token Expired. Login Again.</div>
                        <div
                            className="sidebarCard"
                            onClick={(e) => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            <img src={lock} alt="" style={{ width: "20px" }} />
                            <div style={{ paddingLeft: "10px" }}>Logout</div>
                        </div>
                    </>
                );
            case "success":
                return (
                    <div
                        className="assetDispText"
                        style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "60vh",
                            padding: "0px 20px",
                        }}
                    >
                        {successMsg}
                    </div>
                );
            case "Update State":
                return (
                    <>
                        <div className="sidebarTitle">Select new state</div>
                        <div className="sidebarScroll">
                            {allStates.filter(state => state?._id !== selectedColony?.stateId).map((item, index) => {
                                return <div id={index}
                                    className="sidebarCard"
                                    onClick={() => updateColony(item, "state")}
                                >
                                    <div>{item?.name}</div>
                                </div>
                            })}
                        </div>
                    </>
                )
            case "Update City":
                return (
                    <>
                        <div className="sidebarTitle">Select new city</div>
                        <div className="sidebarScroll">
                            {allCities.filter(city => city?._id !== selectedColony?.cityId).map((item, index) => {
                                return <div id={index}
                                    className="sidebarCard"
                                    onClick={() => updateColony(item, "city")}
                                >
                                    <div>{item?.name}</div>
                                </div>
                            })}
                        </div>
                    </>
                )
            case "Update Area":
                return (
                    <>
                        <div className="sidebarTitle">Select new area</div>
                        <div className="sidebarScroll">
                            {allAreas.filter(area => area?._id !== selectedColony?.areaId).map((item, index) => {
                                return <div id={index}
                                    className="sidebarCard"
                                    onClick={() => updateColony(item, "area")}
                                >
                                    <div>{item?.name}</div>
                                </div>
                            })}
                        </div>
                    </>
                )
            case "Update Pincode":
                return (
                    <>
                        <div className="sidebarTitle">Select pincode</div>
                        <div className="sidebarScroll">
                            {allPincodes.filter(pincode => pincode?._id !== selectedColony?.pincodeId).map((item, index) => {
                                return <div id={index}
                                    className="sidebarCard"
                                    onClick={() => updateColony(item, "area")}
                                >
                                    <div>{item?.pincode}</div>
                                </div>
                            })}
                        </div>
                    </>
                )
            case "Update Colony Name":
                return (
                    <>
                        <div className="sidebarTitle">New Colony Name</div>
                        <div
                        >
                            <input type="text" placeholder="Enter Colony Name..." className="inputStyle" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="bottomSubmit" style={{ background: "#E92729" }} onClick={() => updateColony()}>
                            <p className="btm-text">Submit</p>
                        </div>
                        <div className="bottomSubmit" style={{ bottom: "10px", background: "white", border: "1px solid #E7E7E7" }} onClick={() => {
                            setStep("Action");
                            setPath(["Action"]);
                        }}>
                            <p className="btm-text" style={{ color: "black" }}>Go Back</p>
                        </div>
                    </>
                )
            case "Delete Pincode":
                return (
                    <>
                        <div className="sidebarTitle">Are you sure you want to delete this Pincode?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={deleteColony}
                            >
                                <div>Yes delete it</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Action");
                                    setPath(["Action"]);
                                }}
                            >
                                <div>Never mind</div>
                            </div>
                        </div>
                    </>
                )
            default:
                return (
                    <>
                        <div className="sidebarTitle">What do you want to do?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Colony Name");
                                    setPath([...path, "Update Colony Name"]);
                                }}
                            >
                                <div>Update Colony Name</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update State");
                                    setPath([...path, "Update State"]);
                                    getAllStates()
                                }}
                            >
                                <div>Update State</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update City");
                                    setPath([...path, "Update City"]);
                                    getAllCities()
                                }}
                            >
                                <div>Update City</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Area");
                                    setPath([...path, "Update Area"]);
                                    getAllAreas()
                                }}
                            >
                                <div>Update Area</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Pincode");
                                    setPath([...path, "Update Pincode"]);
                                    getAllPincodes()
                                }}
                            >
                                <div>Update Pincode</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Delete Pincode");
                                    setPath([...path, "Delete Pincode"]);
                                }}
                            >
                                <div>Delete Pincode</div>
                            </div>
                        </div>
                    </>
                )
        }
    }



    return (
        <div
            className={colonyDrawerOpen ? "right-drawer-visible" : "right-drawer-hidden"}
            style={{ height: window.innerHeight - 123, top: 132 }}
        >
            <div
                style={{
                    padding: "30px",
                    height: "90px",
                }}
            >
                {
                    step === "loading" ||
                        step === "Token Expired" ||
                        step == "success" ? (
                        ""
                    ) : (
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div>
                                        <div style={{ fontSize: "22px" }}>
                                            {selectedColony?.name}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div
                                        className="backButton icon-imgs colorImg"
                                        onClick={(e) => {
                                            setPath([]);
                                            SetColonyDrawerOpen(false);
                                        }}
                                        style={{
                                            marginLeft: "20px",
                                        }}
                                    >
                                        <img src={PurpleClose} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div
                                className="breadcrumb"
                                style={{ display: "flex", flexDirection: "row" }}
                            >
                                {path?.map((item, index) => {
                                    return (
                                        <div>
                                            {index !== 0 ? <span>&nbsp;{`->`}&nbsp;</span> : ""}
                                            <span
                                                onClick={(e) => handleBreadcrumbClick(item)}
                                                className={
                                                    index === path.length - 1 ? "crumbSelected" : "crumbs"
                                                }
                                            >
                                                {item}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                {conditionalSteps()}
                <ToastContainer />
            </div>
        </div>
    );
};

export default ColonyDrawer;
