import React from "react";
import { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FilterType from "../Filters/FilterType";
import UserFilter from "../Filters/UserFilter";
import CoinFilter from "../Filters/CoinFilter";
// import RightButton from "../RightButton";
import "./nav.scss";
import { GlobalContex } from "../../globalContex";
import { useEffect } from "react";

import userIcon from "../../static/images/icons/user.svg";
import affilIcon from "../../static/images/icons/affiliate.svg";
import bondIcon from "../../static/images/icons/bondIcon.svg";
import appIcon from "../../static/images/icons/app.svg";
import calendar from "../../static/images/icons/calendar.svg";

import filterIcon from "../../static/images/filter.svg";
import GlobalFilterDrawer from "../GlobalFilterDrawer";

const NavBar = ({
  logo,
  name,
  tabs,
  tabSelected,
  setTabSelected,
  enabledFilters,
  customFilters,
  apiLoading, setApiLoading
}) => {
  //   const [tabSelected, setTabSelected] = useState(null);
  const {
    selectedFilter1,
    setSelectedFilter1,
    selectedFilter2,
    setSelectedFilter2,
    selectedFilter21,
    setSelectedFilter21,
    filter1,
    setFilter1,
    filter2,
    setFilter2,
    customerEmailFilter,
    setCustomerEmailFilter,
    allCoins,
    setAllCoins,
    selectedCoin,
    setSelectedCoin,
    openCoinFilter,
    setOpenCoinFilter,
    refetchApi,
    setRefetchApi,
    setShowSubDraw,
    filters,
    setFilters,
    globalFilter,
    setGlobalFilter,
    AddNewField,
    setAddNewField,
    setTheFormData,
    TheFormData,
    selectedFormData,
    categoryTrigger, setCategoryTrigger,
    filterTrigger, setFilterTrigger,
    selectedAddNew,
    setSubCategoryTrigger,
    setBrandTrigger,
    setCoverTrigger,
    setProductTrigger,
    setBusinessTrigger,
    setAdminTrigger,
    refetchProduct, setRefechProduct,
    editForm, setEditForm,
    editProductTrigger, setEditProductTrigger,
    setEditCategoryTrigger,
    setEditSubCategoryTrigger,
    setEditFilterTrigger,
    setEditBrandTrigger,
    setPincodeTrigger,
    setCouponTrigger,
    setStateTrigger,
    setCityTrigger,
    setAreaTrigger,
    setLogPinCodeTrigger,
    setColonyTrigger,
    setFactoryTrigger,
    setfactoryLicenseTypeTrigger,
    setfactoryLicenseTrigger,
    setfactoryServicingCityTrigger,
    setMachineCategoryTrigger,
    setMachineTrigger,
    setVehicleCategoryTrigger,
    setVehicleTrigger,
    setLogisticHubTrigger
  } = useContext(GlobalContex);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    setShowSubDraw(false);
  }, [tabSelected]);

  useEffect(() => {
    console.log(tabSelected, "kwbdkjwed");
    switch (tabSelected) {
      case "Earnings Per Customer":
        setFilters([
          {
            name: "User",
            icon: userIcon,
          },
          {
            name: "Date",
            icon: calendar,
          },
        ]);
        break;
      default:
        setFilters([
          {
            name: "User",
            icon: userIcon,
          },
          {
            name: "Affiliate",
            icon: affilIcon,
          },
          {
            name: "Bond Offering",
            icon: bondIcon,
          },
          {
            name: "Bond Contract",
            icon: bondIcon,
          },
          {
            name: "Bond",
            icon: bondIcon,
          },
          {
            name: "App",
            icon: appIcon,
          },
        ]);
        break;
    }
  }, [tabSelected]);

  useEffect(() => {
    setSelectedFilter1(filters[0]);
  }, [filters]);


  const discardData = () => {
    if (TheFormData) return setEditForm(!editForm)
    setAddNewField(false)
    // navigate("/Admin")
    console.log(selectedAddNew, "AddNewData")
    if (selectedAddNew === "Admin") {
      setTabSelected("Staff")
    }
  }

  const triggeredData = () => {
    console.log(selectedFormData, selectedAddNew, "aaaaa")
    if (editForm) {
      if (selectedFormData === "Product") {
        setEditProductTrigger(true)
      } else if (selectedFormData === "Categories") {
        setEditCategoryTrigger(true)
      } else if (selectedFormData === "Sub-Categories") {
        setEditSubCategoryTrigger(true)
      } else if (selectedFormData === "Filters") {
        setEditFilterTrigger(true)
      } else if (selectedFormData === "Brands") {
        setEditBrandTrigger(true)
      }
    }
    if (selectedAddNew === "Category") {
      setCategoryTrigger(true)
    } else if (selectedAddNew === "Sub-Category") {
      setSubCategoryTrigger(true)
    } else if (selectedAddNew === "Filter") {
      setFilterTrigger(true)
    } else if (selectedAddNew === "Brand") {
      setBrandTrigger(true)
    } else if (selectedAddNew === "Cover") {
      setCoverTrigger(true)
    } else if (selectedAddNew === "Product") {
      setProductTrigger(true)
    } else if (selectedAddNew === "Business") {
      setBusinessTrigger(true)
    } else if (selectedAddNew === "Admin") {
      setAdminTrigger(true)
    } else if (selectedAddNew === "Pincode") {
      setPincodeTrigger(true)
    } else if (selectedAddNew === "Coupons") {
      setCouponTrigger(true)
    } else if (selectedAddNew === "State") {
      setStateTrigger(true)
    } else if (selectedAddNew === "City") {
      setCityTrigger(true)
    } else if (selectedAddNew === "Area") {
      setAreaTrigger(true)
    } else if (selectedAddNew === "Logistic Pincode") {
      setLogPinCodeTrigger(true)
    } else if (selectedAddNew === "Colony") {
      setColonyTrigger(true)
    } else if (selectedAddNew === "Factory") {
      setFactoryTrigger(true)
    } else if (selectedAddNew === "Factory License Type") {
      setfactoryLicenseTypeTrigger(true)
    } else if (selectedAddNew === "Factory License") {
      setfactoryLicenseTrigger(true)
    } else if (selectedAddNew === "Factory Servicing City") {
      setfactoryServicingCityTrigger(true)
    } else if (selectedAddNew === "Machine Category") {
      setMachineCategoryTrigger(true)
    } else if (selectedAddNew === "Machine") {
      setMachineTrigger(true)
    } else if (selectedAddNew === "Vehicle Category") {
      setVehicleCategoryTrigger(true)
    } else if (selectedAddNew === "Vehicle") {
      setVehicleTrigger(true)
    } else if (selectedAddNew === "Logistics Hub") {
      setLogisticHubTrigger(true)
    }


  }

  // {
  //   setTabSelected(tabItm);
  //   console.log(tabItm, "jhwdjwed");
  // }

  const clickedTab = (item) => {
    console.log(item, "jhwdjwed")
    if (item === "Go Back") {
      console.log(selectedFormData, "dfefe")
      setTheFormData(false)
      setEditForm(false)
    } else {
      setTabSelected(item);
    }
  }

  return (
    <>
      <div className="desktopWrapper">
        <div style={{ borderBottom: "#e7e7e780 solid 1.5px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: window.innerWidth - 485
              // alignItems: "center",
            }}
          >
            <div className="tab">
              {tabs.map((tabItm) => (
                <div
                  key={tabItm}
                  className={
                    "tabitm" + " " + (tabSelected === tabItm ? "true" : "")
                  }
                  onClick={(item) => clickedTab(tabItm)}
                >
                  <h6 style={{ flexWrap: "nowrap" }}>{tabItm}</h6>
                </div>
              ))}
            </div>
            <div style={{ display: "flex" }} className="navBtns">
              <div className="theBtnGroup" style={{ display: AddNewField || TheFormData ? "" : "none" }}>
                <div className="discardBtn" style={{ background: editForm ? "#EA0F0F" : "" }} onClick={discardData}>{editForm ? "Discard" : AddNewField ? "Discard" : "Edit"}</div>
                <div className="saveBtn" onClick={triggeredData} style={{ opacity: TheFormData ? editForm ? "1" : "0.5" : "1" }}>{apiLoading ? "Saving..." : "Save"}</div>
              </div>
              {customFilters ? customFilters : ""}
              <div
                className={
                  enabledFilters[0] ? "coin-button nav-user" : "nav-user"
                }
                onClick={(e) => setGlobalFilter(!globalFilter)}
              >
                <img
                  // style={{ opacity: enabledFilters[0] ? 1 : 0.3 }}
                  src={filterIcon}
                  alt=""
                  width="20px"
                />
              </div>

              <div
                onClick={(e) => setRefechProduct(!refetchProduct)}
                className={
                  enabledFilters[4] ? "coin-button nav-user" : "nav-user"
                }
              >
                <img
                  // style={{ opacity: enabledFilters[4] ? 1 : 0.3 }}
                  src={require("../../static/images/icons/refresh.svg").default}
                  alt=""
                />
              </div>
              {/* <div
                className={
                  enabledFilters[5] ? "coin-button nav-user" : "nav-user"
                }
              >
                <img
                  style={{ opacity: enabledFilters[5] ? 1 : 0.3 }}
                  src={require("../../static/images/icons/search.svg").default}
                  alt=""
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="mobileWrapper">
        <div style={{ borderBottom: "#e7e7e780 solid 1.5px" }}>
          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            <div className="tab" style={{ overflowX: "scroll" }}>
              {tabs.map((tabItm) => (
                <div
                  key={tabItm}
                  className={
                    "tabitm" + " " + (tabSelected === tabItm ? "true" : "")
                  }
                  onClick={() => {
                    setTabSelected(tabItm);
                    console.log(tabItm, "jhwdjwed");
                    //   tabClick();
                  }}
                >
                  <h6 style={{ flexWrap: "nowrap" }}>{tabItm}</h6>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {globalFilter ? <GlobalFilterDrawer tabSelected={tabSelected}
      /> : ""}
      {filter1 ? (
        <FilterType
          onClose={() => setFilter1(false)}
          onSuccess={() => setFilter1(false)}
          allCoins={allCoins}
          filter1={filter1}
          setFilter1={setFilter1}
          selectedCoin={selectedCoin}
          setSelectedCoin={setSelectedCoin}
          selectedFilter1={selectedFilter1}
          setSelectedFilter1={setSelectedFilter1}
          tabSelected={tabSelected}
          setSelectedFilter21={setSelectedFilter21}
          selectedFilter21={selectedFilter21}
        />
      ) : (
        ""
      )}
      {filter2 ? (
        <UserFilter
          onClose={() => setFilter2(false)}
          onSuccess={() => setFilter2(false)}
          allCoins={allCoins}
          filter2={filter2}
          setFilter2={setFilter2}
          selectedCoin={selectedCoin}
          setSelectedCoin={setSelectedCoin}
          selectedFilter2={selectedFilter2}
          setSelectedFilter2={setSelectedFilter2}
          selectedFilter21={selectedFilter21}
          setSelectedFilter21={setSelectedFilter21}
          customerEmailFilter={customerEmailFilter}
          setCustomerEmailFilter={setCustomerEmailFilter}
          refetchApi={refetchApi}
          setRefetchApi={setRefetchApi}
          query={query}
          setQuery={setQuery}
          tabSelected={tabSelected}
        />
      ) : (
        ""
      )}

      {openCoinFilter ? (
        <CoinFilter
          onClose={() => setOpenCoinFilter(false)}
          onSuccess={() => setOpenCoinFilter(false)}
          allCoins={allCoins}
          openCoinFilter={openCoinFilter}
          setOpenCoinFilter={setOpenCoinFilter}
          selectedCoin={selectedCoin}
          setSelectedCoin={setSelectedCoin}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default NavBar;
